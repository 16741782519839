import React, {FormEvent, useCallback, useMemo, useState} from 'react';
// Styles
import styles from './ChangeData.module.scss';
import Button from '@components/Button/Button';
import {Title} from '@components/_Typography';
import {TitleTag, TitleVariant} from '@components/_Typography/Title/Title';
import Input, {defaultInput} from '@components/_FormElements/Input/Input';
import {FormField, FormKeys} from '@commonTypes/main';
import {validateForm} from '@helpers/validateForm';
import {toast} from 'react-toastify';
import {dataChange} from '@api/user';
import {UserDetailsModel} from '@api/models/authModels';
import { updateUserDataAction } from '@actions/authActions';
import { useDispatch } from 'react-redux';

const initialState = {
	name: {...defaultInput, required: true},
	lastName: {...defaultInput, required: true},
	company: {...defaultInput, required: false},
	phone: {...defaultInput, required: false},
};

interface ChangeDataProps {
	userData: Partial<UserDetailsModel>;
}

const ChangeData = (props: ChangeDataProps) => {
	const {userData} = props;

	const dispatch = useDispatch()

	const [form, setForm] = useState<FormKeys<typeof initialState>>({
		...initialState,
		name: {...initialState.name, value: userData.firstName},
		lastName: {...initialState.lastName, value: userData.lastName},
		company: {...initialState.company, value: userData.company},
		phone: {...initialState.phone, value: userData.phone},
	});

	const updateFormHandler = (name: string, value: FormField) => {
		setForm({...form, [name]: value});
	};

	const submitHandler = useCallback(
		async (e: FormEvent) => {
			e.preventDefault();
			const isError = validateForm(form, setForm);

			if (isError) return toast.warning('Popraw formularz.');

			await dataChange({
				firstName: form.name.value,
				lastName: form.lastName.value,
				company: form.company.value,
				phone: form.phone.value,
			}).then((res) => dispatch(updateUserDataAction(res)));
		},
		[dispatch, form]
	);

	const noDifference = useMemo(
		() =>
			userData.firstName === form.name.value &&
			userData.lastName === form.lastName.value &&
			userData.company === form.company.value &&
			userData.phone === form.phone.value,
		[
			userData.firstName,
			userData.lastName,
			userData.company,
			userData.phone,
			form.name.value,
			form.lastName.value,
			form.company.value,
			form.phone.value,
		]
	);

	return (
		<div className={styles.wrapper}>
			<Title
				tag={TitleTag.h2}
				variant={TitleVariant.standard}
				classes={styles.title}>
				Zmiana danych
			</Title>
			<form onSubmit={submitHandler}>
				<div className={styles.formFields}>
					<Input
						id='name'
						name='name'
						type='text'
						label={'Imię'}
						placeholder={'Wpisz imię'}
						required={form.name.required}
						value={form.name.value}
						errorMessage={form.name.errorMessage}
						valueChangeHandler={updateFormHandler}
					/>
					<Input
						id='lastName'
						name='lastName'
						type='text'
						label={'Nazwisko'}
						placeholder={'Wpisz nazwisko'}
						required={form.lastName.required}
						value={form.lastName.value}
						errorMessage={form.lastName.errorMessage}
						valueChangeHandler={updateFormHandler}
					/>
					<Input
						id='company'
						name='company'
						type='text'
						label={'Nazwa firmy'}
						placeholder={'Wpisz nazwę firmy'}
						required={form.company.required}
						value={form.company.value}
						errorMessage={form.company.errorMessage}
						valueChangeHandler={updateFormHandler}
					/>
					<Input
						id='phone'
						name='phone'
						type='text'
						label={'Telefon'}
						placeholder={'Wpisz telefon'}
						required={form.phone.required}
						value={form.phone.value}
						errorMessage={form.phone.errorMessage}
						valueChangeHandler={updateFormHandler}
					/>
				</div>
				<Button classes={styles.submitBtn} type='submit' disabled={noDifference}>
					Zapisz ustawienia
				</Button>
			</form>
		</div>
	);
};

export default ChangeData;
