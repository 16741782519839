import React from 'react';
import {InputProps} from '../Input/Input';
import styles from './RangeInput.module.scss';
import Checkbox from '../Checkbox/Checkbox';

interface RangeInputProps extends InputProps {
	rangeValues: number[]; // Define specific allowed values
	scaleLabel?: string; // Label for the scale
}

const RangeInput = (props: RangeInputProps) => {
	const {
		rangeValues,
		name,
		id,
		value,
		valueChangeHandler,
		scaleLabel,
		label,
		errorMessage,
	} = props;

	if (rangeValues.length === 0) return null;

	return (
		<div className={styles.wrapper}>
			<label htmlFor={id} className={styles.label}>
				{label}
			</label>
			<div className={styles.inline}>
				<div className={styles.range}>
					{rangeValues.map((range) => (
						<Checkbox
							key={`${range}`}
							id={`${range}`}
							type='radio'
							name={`${name}`}
							label={`${range} ${scaleLabel}`}
							required={false}
							value={+value === range}
							valueChangeHandler={valueChangeHandler}
							toggler
						/>
					))}
				</div>
			</div>
			<p className={styles.error}>{errorMessage}</p>
		</div>
	);
};

export default RangeInput;
