import {StatusCodes} from '@commonTypes/main';

// Body types
export interface BodyModel {
	id: number;
	name: string;
	image: string;
}

export interface GetBodyTypesResponse {
	value: BodyModel[];
	statusCode: StatusCodes;
}

// Models types
export interface ModelModel {
	modelId: number;
	name: string;
}

export interface GetModelResponse {
	value: ModelModel[];
	statusCode: StatusCodes;
}

// Cabin types
export interface CabinTypeModel {
	id: number;
	name: string | null;
	imageUrl: string;
}

export interface GetCabinTypeResponse {
	value: CabinTypeModel[];
	statusCode: StatusCodes;
}

// Chassis types
export interface ChassisModel {
	id: number;
	chassis: string;
}

export interface GetChassisResponse {
	value: ChassisModel[];
	statusCode: StatusCodes;
}

// Vechicle types
export interface SizeRangeModel {
	value: number;
	price: number;
	weight: number;
}

export enum SpoilerOption {
	NONE = 0,
	OPTIONAL = 1,
	REQUIRED = 2,
}

export interface HeightRangeModel extends SizeRangeModel {
	spoilerOptionId: SpoilerOption;
}

export interface VechicleModel {
	id: number;
	model: string;
	modelId: number;
	brandId: number;
	brand: string;
	cabinType: string;
	cabinTypeId: number;
	cabinTypeImg: string;
	bodyType: string;
	bodyTypeId: number;
	bodyTypeImg: string;
	chassis: string;
	vechicleAvailableLenghts: SizeRangeModel[];
	vechicleAvailableWidths: SizeRangeModel[];
	vechicleAvailableHeights: HeightRangeModel[];
}

export enum DimensionTypes {
	Length = 1,
	Height = 2,
	Width = 3,
}

export interface PropertyDimensionRangeModel {
	attributeId: number;
	dimensionType: string;
	dimensionTypeId: DimensionTypes;
	isAttributeRequired: boolean;
	maxValue: number;
	minValue: number;
}

export interface AttributeModel {
	attributeId: number;
	name: string;
	vechicleId: number;
	categoryId: number;
	categoryName: string;
	description: string;
	price: number;
	weight: number;
	imageUrl: string;
	isEuroPrice: boolean;
	isHidden: boolean;
	isRequired: boolean;
	attributeDimension: PropertyDimensionRangeModel | null;
	dependentAttributeIds: number[];
	excludedAttributeIds: number[];
}

export interface CategoryModel {
	tabId: number;
	categoryId: number;
	categoryName: string;
	isRequiredAttributeFromCategory: boolean;
	attributes: AttributeModel[];
}

export interface TabModel {
	tabId: number;
	vechicleId: number;
	tabName: string;
	categories: CategoryModel[];
}

export interface GetVechicleResponse {
	value: {vechicle: VechicleModel; tabAttributes: TabModel[]};
	statusCode: StatusCodes;
}

// Config List

export interface SavedConfigListItemModel {
	basePrice: number;
	baseWeight: number;
	cabin: string;
	chassis: string;
	configurationItems: number[];
	configurationName: string;
	height: number;
	id: number;
	lenght: number;
	mark: string;
	model: string;
	totalPrice: number;
	totalWeight: number;
	vechicleId: number;
	width: number;
}

export interface GetConfigListResponse {
	value: {
		paginatedConfigurationList: SavedConfigListItemModel[];
		pageIndex: number;
		totalPages: number;
		itemsCount: number;
	};
	statusCode: number;
}
