// Liobraries
import {FormEvent, useCallback, useEffect, useMemo, useState} from 'react';
// Components
import Container from '@components/Container/Container';
import {Title} from '@components/_Typography';
import Button, {ButtonTag, ButtonVariant} from '@components/Button/Button';
import Logo from '@components/Logo/Logo';
import Input, {defaultInput} from '@components/_FormElements/Input/Input';
// Types
import {Tag} from '@commonTypes/tags';
import {TitleTag, TitleVariant} from '@components/_Typography/Title/Title';
// API
import {getBrandsRequest} from '@api/brands';
// Types
import {FormField, FormKeys} from '@commonTypes/main';
import {BrandModel} from '@api/models/brandsModels';
// Styles
import styles from './Register.module.scss';
import Select from '@components/_FormElements/Select/Select';
import {toast} from 'react-toastify';
import PasswordCheck from '@components/_FormElements/PasswordCheck/PasswordCheck';
import {validateForm} from '@helpers/validateForm';
import {register} from '@api/auth';
import {FormSteps} from '@components/ContactForm/ContactForm';
import Loader from '@components/Loader/Loader';
import SuccessStep from '@components/ContactForm/Steps/SuccessStep';
import ErrorStep from '@components/ContactForm/Steps/ErrorStep';

const initialState = {
	name: {...defaultInput, required: true},
	lastName: {...defaultInput, required: true},
	email: {...defaultInput, required: true},
	password: {...defaultInput, required: true},
	passwordRepeat: {...defaultInput, required: true},
	brand: {...defaultInput, value: 0, required: true},
	phone: {...defaultInput, required: true},
	company: {...defaultInput, required: true},
};

const Register = () => {
	const [brands, setBrands] = useState<BrandModel[]>([]);
	const [form, setForm] = useState<FormKeys<typeof initialState>>(initialState);
	const [step, setStep] = useState<FormSteps>(FormSteps.Form);

	const updateFormHandler = (name: string, value: FormField) => {
		if (name === 'passwordRepeat') {
			setForm({
				...form,
				[name]: {
					...value,
					errorMessage:
						form.passwordRepeat.value !== form.password.value
							? 'Hasła do siebie nie pasują.'
							: '',
				},
			});
			return;
		}
		setForm({...form, [name]: value});
	};

	const submitHandler = useCallback(
		async (e: FormEvent) => {
			e.preventDefault();
			const isError = validateForm(form, setForm);

			if (isError) return toast.warning('Popraw formularz.');

			setStep(FormSteps.Sending);

			await register({
				login: form.email.value,
				password: form.password.value,
				firstName: form.name.value,
				lastName: form.lastName.value,
				brandId: form.brand.value,
				company: form.company.value,
				phone: form.phone.value,
			})
				.then(() => {
					setStep(FormSteps.Success);
					setForm(initialState);
				})
				.catch(() => {
					setStep(FormSteps.Error);
				});
		},
		[form]
	);

	useEffect(() => {
		(async () => {
			await getBrandsRequest()
				.then((res) => {
					setBrands(res);
				})
				.catch(() => {
					toast.error('Nie można pobrać marek. Rejestracja niemożliwa.');
				});
		})();
	}, []);

	const currentStep = useMemo(() => {
		switch (step) {
			case FormSteps.Form:
				return;
			case FormSteps.Sending:
				return <Loader title='Przesyłanie rejestracji.' />;
			case FormSteps.Success:
				return (
					<>
						<SuccessStep
							title={
								<span>
									Konto przekazane do aktywacji.
									<br />
									Aktywacja nastąpi w przeciągu 24h w dniu roboczym.
								</span>
							}
						/>
						<Button
							as={ButtonTag.InternalLink}
							variant={ButtonVariant.Primary}
							classes={styles.backToLogin}
							href='/login'>
							Powrót do logowania
						</Button>
					</>
				);
			case FormSteps.Error:
				return (
					<>
						<ErrorStep />
						<Button
							variant={ButtonVariant.Primary}
							classes={styles.backToLogin}
							onClick={() => {
								setStep(FormSteps.Form);
							}}>
							Spróbuj jeszcze raz
						</Button>
					</>
				);
			default:
				throw new Error('Unknown ContactForm step.');
		}
	}, [step]);

	return (
		<Container as={Tag.Main} classes={styles.wrapper}>
			<section className={styles.formWrapper}>
				<Logo />
				<Title
					tag={TitleTag.h1}
					variant={TitleVariant.section}
					classes={styles.title}>
					Rejestracja
				</Title>
				<form onSubmit={submitHandler}>
					{step === FormSteps.Form ? (
						<>
							<div className={styles.fieldsWrapper}>
								<Input
									id='name'
									name='name'
									type='text'
									placeholder={'Wpisz swoje imię'}
									label={'Imię'}
									required={form.brand.required}
									value={form.name.value}
									errorMessage={form.name.errorMessage}
									valueChangeHandler={updateFormHandler}
								/>
								<Input
									id='lastName'
									name='lastName'
									type='text'
									placeholder={'Wpisz swoje nazwisko'}
									label={'Nazwisko'}
									required={form.lastName.required}
									value={form.lastName.value}
									errorMessage={form.lastName.errorMessage}
									valueChangeHandler={updateFormHandler}
								/>
								<Input
									id='email'
									name='email'
									type='email'
									placeholder={'Wpisz e-mail'}
									label={'E-mail'}
									required={form.email.required}
									value={form.email.value}
									errorMessage={form.email.errorMessage}
									valueChangeHandler={updateFormHandler}
								/>
								<Select<number | undefined>
									id='brand'
									name='brand'
									label='Marka'
									value={form.brand.value}
									errorMessage={form.brand.errorMessage}
									valueChangeHandler={updateFormHandler}
									defaultOption={{value: 0, label: 'Przypisz markę do konta'}}
									options={brands?.map(({id, name}) => ({value: id, label: name}))}
									required
								/>
								<div>
									<Input
										id='password'
										name='password'
										type='password'
										label={'Hasło'}
										placeholder={'Wpisz hasło'}
										required={form.password.required}
										value={form.password.value}
										errorMessage={form.password.errorMessage}
										valueChangeHandler={updateFormHandler}
									/>
									<PasswordCheck password={form.password.value} />
								</div>
								<Input
									id='passwordRepeat'
									name='passwordRepeat'
									type='password'
									label={'Powtórz hasło'}
									placeholder={'Wpisz ponownie hasło'}
									required={form.passwordRepeat.required}
									value={form.passwordRepeat.value}
									errorMessage={form.passwordRepeat.errorMessage}
									valueChangeHandler={updateFormHandler}
								/>
								<Input
									id='company'
									name='company'
									type='text'
									placeholder={'Wpisz nazwę firmy'}
									label={'Nazwa firmy'}
									required={form.company.required}
									value={form.company.value}
									errorMessage={form.company.errorMessage}
									valueChangeHandler={updateFormHandler}
								/>
								<Input
									id='phone'
									name='phone'
									type='phone'
									label={'Numer telefonu'}
									placeholder={'Wpisz numer telefonu'}
									required={form.phone.required}
									value={form.phone.value}
									errorMessage={form.phone.errorMessage}
									valueChangeHandler={updateFormHandler}
								/>
							</div>
							<div className={styles.buttons}>
								<Button classes={styles.submitBtn} type='submit' fullWidth>
									Utwórz konto
								</Button>
								<Button
									fullWidth
									as={ButtonTag.InternalLink}
									variant={ButtonVariant.OutlineWithBorder}
									classes={styles.submitBtn}
									href='/login'>
									Mam już konto
								</Button>
							</div>
						</>
					) : (
						currentStep
					)}
				</form>
			</section>
		</Container>
	);
};

export default Register;
