import {requestCreator} from '@helpers/requsetCreator';
import {FullOfferModel, GetOfferResponse} from './models/offer';
import {Dispatch} from 'react';
import {UnknownAction} from '@reduxjs/toolkit';
import {setOrderedConfigs} from '@actions/orderedConfigsPageActions';

export const createOfferRequest = async (basketGuid: string, offerName: string): Promise<string> =>
	await requestCreator<undefined, string>(
		'POST',
		`/offer/?basketGuid=${basketGuid}&offerName=${offerName}`,
		undefined,
		undefined,
		'Wygenerowano wycenę. Możesz ją podejrzeć w liście wycen.'
	);

export const getOffersRequest = async (
	dispatch: Dispatch<UnknownAction>,
	pageIndex: number,
	pageSize: number
): Promise<GetOfferResponse> => {
	const res = await requestCreator<undefined, GetOfferResponse>(
		'GET',
		`/offer?pageIndex=${pageIndex}&pageSize=${pageSize}`
	);

	dispatch(setOrderedConfigs(res));

	return res;
};

export const getOfferDetailsRequest = async (
	id: string
): Promise<FullOfferModel> =>
	await requestCreator<undefined, FullOfferModel>('GET', `/offer/details/${id}`);
