// Helpers
import {requestCreator} from '@helpers/requsetCreator';
// Types
import {
	GetBodyTypesResponse,
	GetCabinTypeResponse,
	GetChassisResponse,
	GetConfigListResponse,
	GetModelResponse,
	GetVechicleResponse,
} from './models/configuration';
import {Dispatch} from 'react';
import {UnknownAction} from '@reduxjs/toolkit';
import {setSavedConfigs} from '@actions/savedConfigsPageActions';

export const getBodyTypes = async (
	brandId: number
): Promise<GetBodyTypesResponse> =>
	await requestCreator<undefined, GetBodyTypesResponse>(
		'GET',
		`/configuration/bodyTypes?brandId=${brandId}`
	);

export const getModels = async (
	brandId: number,
	bodyTypeId: number
): Promise<GetModelResponse> =>
	await requestCreator<undefined, GetModelResponse>(
		'GET',
		`/configuration/models?brandId=${brandId}&bodyTypeId=${bodyTypeId}`
	);

export const getCabinTypes = async (
	bodyTypeId: number,
	modelId: number
): Promise<GetCabinTypeResponse> =>
	await requestCreator<undefined, GetCabinTypeResponse>(
		'GET',
		`/configuration/cabinTypes?modelId=${modelId}&bodyTypeId=${bodyTypeId}`
	);

export const getChassis = async (
	bodyTypeId: number,
	modelId: number,
	cabinTypeId: number | null
): Promise<GetChassisResponse> =>
	await requestCreator<undefined, GetChassisResponse>(
		'GET',
		`/configuration/chassis?modelId=${modelId}&bodyTypeId=${bodyTypeId}${
			!!cabinTypeId ? `&cabinTypeId=${cabinTypeId}` : ''
		}`
	);

export const getVechicle = async (
	vechcicleId: number
): Promise<GetVechicleResponse> =>
	await requestCreator<undefined, GetVechicleResponse>(
		'GET',
		`/configuration/vechicle-details?vechcicleId=${vechcicleId}`
	);

// Config List

export const getConfigurationListRequest = async (
	dispatch: Dispatch<UnknownAction>,
	pageIndex: number,
	pageSize: number
): Promise<GetConfigListResponse> => {
	const res = await requestCreator<undefined, GetConfigListResponse>(
		'GET',
		`configuration/list?pageIndex=${pageIndex}&pageSize=${pageSize}`
	);

	dispatch(setSavedConfigs(res));

	return res;
};

// Submitions

export const saveConfigurationRequest = async (
	basketGuid: string,
	configurationName: string
): Promise<string> =>
	await requestCreator<undefined, string>(
		'POST',
		`/configuration/save?basketGuid=${basketGuid}&configurationName=${configurationName}`,
		undefined,
		undefined,
		'Zapisano konfigurację.'
	);

export const deleteConfigurationRequest = async (id: string): Promise<string> =>
	await requestCreator<undefined, string>(
		'DELETE',
		`/configuration?id=${id}`,
		undefined,
		undefined,
		'Usunięto zapisaną konfigurację.'
	);
