import React, {FormEvent} from 'react';
// Components
import Button from '@components/Button/Button';
// Styles
import styles from './Summary.module.scss';
import {useSelector} from 'react-redux';
import {configuratorSelectors} from '@selectors/configuratorSelectors';

interface SummaryProps {
	width?: number;
	height?: number;
	length?: number;
	submitHandler: (e: FormEvent) => Promise<void>;
}

const Summary = (props: SummaryProps) => {
	const {submitHandler} = props;

	const vechicle = useSelector(configuratorSelectors.getVechicle);

	// const vechicleBody = useMemo(() => {
	// 	return `${vechicle?.bodyType} ${vechicle?.cabinType ?? ''}`;
	// }, [vechicle]);

	// const vechicleName = useMemo(() => {
	// 	return `${vechicle?.brand} ${vechicle?.model} ${vechicle?.chassis}`;
	// }, [vechicle]);

	return (
		<div className={styles.wrapper}>
			{!!vechicle && (
				<>
					{/* <h3 className={styles.title}>Podsumowanie:</h3>
					<div className={styles.summary}>
						<p className={styles.text}>
							{vechicleBody}
							<br />
							{vechicleName}
						</p>
						<p className={styles.text}>
							{width ? (
								<span>
									<strong>Szerokość:</strong> {width}
								</span>
							) : (
								<></>
							)}
							{length ? (
								<span>
									<strong>Długośc:</strong> {length}
								</span>
							) : (
								<></>
							)}
							{height ? (
								<span>
									<strong>Wysokość:</strong> {height}
								</span>
							) : (
								<></>
							)}
						</p>
					</div> */}
					<div className={styles.buttons}>
						<Button type='button' onClick={submitHandler}>
							Podsumowanie
						</Button>
					</div>
				</>
			)}
		</div>
	);
};

export default Summary;
