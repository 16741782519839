import {AllAttributes} from '@actions/configuratorActions';
import {
	DimensionTypes,
	PropertyDimensionRangeModel,
} from '@api/models/configuration';

// Recursive function to find all deep connections
export const findDeeplyConnectedAttributes = (
	attributeId: number,
	allAttributesObject: AllAttributes,
	visited: Set<number> = new Set()
): number[] => {
	// If the attribute has already been visited, we avoid circular dependencies
	if (visited.has(attributeId)) return [];
	// Mark this attribute as visited
	visited.add(attributeId);
	// Get the straight connected attributes
	const straightConnectedAttributes =
		allAttributesObject[+attributeId]?.dependentAttributeIds || [];
	// Recursively get deeply connected attributes for each straight connection
	const deepConnections = straightConnectedAttributes.flatMap((id) =>
		findDeeplyConnectedAttributes(id, allAttributesObject, visited)
	);
	// Return a set of unique connections (combining straight and deep connections)
	return [...new Set([...straightConnectedAttributes, ...deepConnections])];
};

// Recursive function to find all deep exclusions
export const findDeeplyExcludedAttributes = (
	checkedAttributes: number[],
	allAttributesObject: AllAttributes,
	attributeId: number
): number[] => {
	// Get the straight excluded attributes
	const straightExcludedAttributes = (
		allAttributesObject[attributeId]?.excludedAttributeIds || []
	).filter((attrId) => checkedAttributes.includes(attrId));

	// Find every id where find attributeId in exclusions
	// Possibly element can be undefined
	const allAttributesConnectedWithExclusions =
		straightExcludedAttributes.flatMap((id) =>
			allAttributesObject[+id]?.dependentAttributeIds.filter((attrId) =>
				checkedAttributes.includes(attrId)
			)
		);
	const allStrightExclude = Array.from(
		new Set([
			...straightExcludedAttributes,
			...allAttributesConnectedWithExclusions,
		])
	).filter((id) => id !== undefined);

	// Return a unique list of both direct and deep exclusions using Array.from() to handle Set iteration
	return allStrightExclude;
};

// Function to find conflicts between connected and excluded attributes
export const findConflicts = (
	connectedArray: number[],
	excludedArray: number[],
	currentId: number
): number[] => {
	// Find conflicts: attributes that are both connected and excluded
	const conflicts = [currentId, ...connectedArray].filter((attr) =>
		excludedArray.includes(attr)
	);

	return conflicts;
};

// Uncheck Helpers

// Recursive function to find all deep connections
export const findAllRequirements = (
	attributeId: number,
	allAttributesObject: AllAttributes,
	checkedAttributes: number[],
	visited: Set<number> = new Set()
): number[] => {
	// If the attribute has already been visited, we avoid circular dependencies
	if (visited.has(attributeId)) return [];
	// Mark this attribute as visited
	visited.add(attributeId);
	// Get the straight connected attributes
	const straightConnectedAttributes =
		checkedAttributes.filter((id) =>
			allAttributesObject[id]?.dependentAttributeIds.includes(+attributeId)
		) || [];
	// Recursively get deeply connected attributes for each straight connection
	const deepConnections = straightConnectedAttributes.flatMap((id) =>
		findAllRequirements(id, allAttributesObject, checkedAttributes, visited)
	);
	// Return a set of unique connections (combining straight and deep connections)
	return [...new Set([...straightConnectedAttributes, ...deepConnections])];
};

export const hiddenAttributesWithoutConnections = (
	attributeId: number,
	allAttributesObject: AllAttributes,
	checkedAttributes: number[]
) => {
	const newCheckedAttributes = checkedAttributes.filter(
		(id) => id !== +attributeId
	);
	const hiddenAttributesArr = newCheckedAttributes.filter(
		(id) => allAttributesObject[id].isHidden
	);

	let hiddenIncludedInNewChecked: number[] = [];

	newCheckedAttributes.map((id) =>
		allAttributesObject[id].dependentAttributeIds.forEach((id) => {
			if (hiddenAttributesArr.includes(id)) {
				hiddenIncludedInNewChecked.push(id);
			}
		})
	);

	const hiddenAttrToRemove = hiddenAttributesArr.filter(
		(id) => !hiddenIncludedInNewChecked.includes(id)
	);

	return hiddenAttrToRemove;
};

export const checkAttributeDimensions = (
	vehicleWidth: number,
	vehicleHeight: number,
	vehicleLength: number,
	dimension: PropertyDimensionRangeModel | null
) => {
	if(dimension === null) return true

	let isInRange;
	switch (+dimension.dimensionTypeId) {
		case DimensionTypes.Height:
			isInRange = vehicleHeight <= dimension.maxValue && vehicleHeight >= dimension.minValue;
			break;
		case DimensionTypes.Width:
			isInRange = vehicleWidth <= dimension.maxValue && vehicleWidth >= dimension.minValue;
			break;
		case DimensionTypes.Length:
			isInRange = vehicleLength <= dimension.maxValue && vehicleLength >= dimension.minValue;
			break;
		default:
			return;
	}

	if(!isInRange) return false

	return true
};
