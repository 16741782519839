// Actions
import { BasketActions, BasketState } from '@actions/basketActions';
// Types
import { BasketModel } from '@api/models/basket';

const initialState: BasketState = {
  basket: null
};

export const basketReducer = (
	state = initialState,
	action: {type: BasketActions; payload: BasketModel | boolean}
): BasketState => {
	switch (action.type) {
		case BasketActions.CREATE_BASKET:
			return {...state, basket: action?.payload as BasketModel};
		case BasketActions.CLEAR_BASKET:
			return initialState;
		default:
			return state;
	}
};
