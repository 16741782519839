// Components
import Table from '@components/Table/Table';
import Container from '@components/Container/Container';
// Types
import {Tag} from '@commonTypes/tags';
import {useDispatch, useSelector} from 'react-redux';
import {useCallback, useMemo, useState} from 'react';
import {
	getBodyTypes,
	getCabinTypes,
	getChassis,
	getConfigurationListRequest,
	getModels,
	getVechicle,
} from '@api/configuration';
import {
	getSavedConfigs,
	getSavedConfigsMaxPage,
} from '@selectors/savedConfigsPageSelectors';
import Button, {ButtonVariant} from '@components/Button/Button';
import Pagination from '@components/Pagination/Pagination';
import ConfirmModal, {ConfirmModalType} from '../ConfirmModal/ConfirmModal';
import {loadingAction} from '@actions/appActions';
import {getSavedConfigBasket} from '@api/basket';
import {clearBasket, createBasket} from '@actions/basketActions';
import {getAuth} from '@selectors/authSelectors';
import {
	setBodyTypes,
	setCabinTypes,
	setChassis,
	setInitiConfig,
	setModels,
} from '@actions/configuratorActions';
import {ReactComponent as Delete} from '@assets/icons/trash.svg';
import styles from './SavedConfigsList.module.scss';

const SavedConfigsList = () => {
	const [modal, setModal] = useState<ConfirmModalType>(ConfirmModalType.CLOSED);
	const [currentId, setCurrentID] = useState<number | undefined>();

	const dispatch = useDispatch();
	const configs = useSelector(getSavedConfigs);
	const auth = useSelector(getAuth);

	const openModalHandler = useCallback(
		async (type: ConfirmModalType, id: number) => {
			dispatch(loadingAction(true));
			if (type === ConfirmModalType.DELETE) {
				setModal(type);
				setCurrentID(id);
				return;
			}

			const savedConfigRes = await getSavedConfigBasket(id)
				.then((res) => {
					// Data used in ConfirmModal
					dispatch(createBasket(res.value));
					setModal(type);
					return res;
				})
				.finally(() => {
					dispatch(loadingAction(false));
				});

			const vehicleRes = await getVechicle(savedConfigRes.value.vechicleId).then(
				async (res) => {
					const [bodyRes, modelsRes, cabinRes, chassisRes] = await Promise.all([
						getBodyTypes(auth.brandId ?? 0),
						getModels(auth.brandId ?? 0, +res.value.vechicle.bodyTypeId),
						getCabinTypes(
							+res.value.vechicle.bodyTypeId,
							+res.value.vechicle.modelId
						),
						getChassis(
							+res.value.vechicle.bodyTypeId,
							+res.value.vechicle.modelId,
							res.value.vechicle.cabinType === null
								? null
								: +res.value.vechicle.cabinType
						),
					]);

					dispatch(setBodyTypes(bodyRes.value));
					dispatch(setModels(modelsRes.value));
					dispatch(setCabinTypes(cabinRes.value));
					dispatch(setChassis(chassisRes.value));

					return res;
				}
			);

			dispatch(
				setInitiConfig({
					vechicleId: savedConfigRes.value.vechicleId,
					modelId: vehicleRes.value.vechicle.modelId,
					bodyTypeId: vehicleRes.value.vechicle.bodyTypeId,
					cabinId: vehicleRes.value.vechicle.cabinTypeId,
					attributes: savedConfigRes.value.attributes,
					width: savedConfigRes.value.width,
					heigth: savedConfigRes.value.height,
					length: savedConfigRes.value.lenght,
				})
			);
		},
		[auth?.brandId, dispatch]
	);

	const closeModalHandler = () => {
		setModal(ConfirmModalType.CLOSED);
		dispatch(clearBasket());
		setCurrentID(undefined);
	};

	const tableElements = useMemo(
		() =>
			configs?.map((item) => {
				return {
					id: item.id,
					configName: item.configurationName,
					name: `${item.mark} ${item.model} ${item.chassis} ${
						!!item.cabin ? `kabina ${item.cabin}` : ''
					}`,
					config: (
						<Button
							type='button'
							variant={ButtonVariant.Outline}
							onClick={() => openModalHandler(ConfirmModalType.MODIFY, item.id)}>
							Odtwórz
						</Button>
					),
					delete: (
						<button
							className={styles.button}
							type='button'
							onClick={() => openModalHandler(ConfirmModalType.DELETE, item.id)}>
							<Delete />
						</button>
					),
				};
			}),
		[configs, openModalHandler]
	);

	return (
		<>
			<Container as={Tag.Section}>
				<Table
					headers={[
						{text: 'ID', sortName: 'id'},
						{text: 'Nazwa', sortName: 'configName'},
						{text: 'Pojazd', sortName: 'name'},
						{text: 'Odtwórz'},
						{text: 'Usuń'},
					]}
					elements={tableElements ?? []}
					groups={[
						{width: '10%'},
						{width: '35%', minWidth: 150},
						{width: '35%', minWidth: 225},
						{width: '15%'},
						{width: '5%'},
					]}
				/>

				<Pagination
					request={getConfigurationListRequest}
					getMaxPages={getSavedConfigsMaxPage}
				/>
			</Container>
			{modal !== ConfirmModalType.CLOSED && (
				<ConfirmModal
					type={modal}
					closeHandler={closeModalHandler}
					currentId={currentId}
				/>
			)}
		</>
	);
};

export default SavedConfigsList;
