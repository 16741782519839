import {BasketAttributeModel} from '@api/models/basket';
import {
	AttributeModel,
	BodyModel,
	CabinTypeModel,
	ChassisModel,
	ModelModel,
	TabModel,
	VechicleModel,
} from '@api/models/configuration';
import {Tab} from '@reducers/configuratorReducer';

export interface AttributeDependency {
	[key: number]: number[];
}

export interface AllAttributes {
	[key: number]: AttributeModel;
}

export interface InitConfigModel {
	vechicleId: number;
	modelId: number;
	bodyTypeId: number;
	cabinId: number | null;
	attributes: BasketAttributeModel[];
	width: number;
	heigth: number;
	length: number;
}

export interface ConfiguratorModel {
	bodyTypes: BodyModel[] | null;
	models: ModelModel[] | null;
	cabinTypes: CabinTypeModel[] | null;
	chassis: ChassisModel[] | null;
	vechicle: VechicleModel | null;
	tabAttributes: TabModel[] | null;
	currentTab: Tab | number;
	availableSizeRange: boolean;
	attributesDependencies: {
		allAttributes: AllAttributes;
		connected: AttributeDependency;
		excluded: AttributeDependency;
	};
	initConfig: InitConfigModel | null;
}

export enum ConfiguratorActions {
	SET_BODY_TYPES = 'SET_BODY_TYPES',
	SET_MODELS = 'SET_MODELS',
	SET_CABIN_TYPES = 'SET_CABIN_TYPES',
	SET_CHASSIS = 'SET_CHASSIS',
	SET_VECHICLE = 'SET_VECHICLE',
	SET_TAB_ATTRIBUTES = 'SET_TAB_ATTRIBUTES',
	CLEAR_VEHICLE_AND_ATTRIBUTES = 'CLEAR_VEHICLE_AND_ATTRIBUTES',
	SET_CURRENT_TAB = 'SET_CURRENT_TAB',
	SET_INIT_CONFIG = 'SET_INIT_CONFIG',
}

const setBodyTypes = (bodyTypes: BodyModel[]) => ({
	type: ConfiguratorActions.SET_BODY_TYPES,
	payload: bodyTypes,
});

const setInitiConfig = (init: InitConfigModel | null) => ({
	type: ConfiguratorActions.SET_INIT_CONFIG,
	payload: init,
});

const setModels = (models: ModelModel[]) => ({
	type: ConfiguratorActions.SET_MODELS,
	payload: models,
});

const setCabinTypes = (cabinTypes: CabinTypeModel[]) => ({
	type: ConfiguratorActions.SET_CABIN_TYPES,
	payload: cabinTypes,
});

const setChassis = (chasiss: ChassisModel[]) => ({
	type: ConfiguratorActions.SET_CHASSIS,
	payload: chasiss,
});

const setVechicle = (vechicle: VechicleModel) => ({
	type: ConfiguratorActions.SET_VECHICLE,
	payload: vechicle,
});

const setTabAttributes = (tabAttributes: TabModel[]) => ({
	type: ConfiguratorActions.SET_TAB_ATTRIBUTES,
	payload: tabAttributes,
});

const clearVechicleAndAttributes = () => ({
	type: ConfiguratorActions.CLEAR_VEHICLE_AND_ATTRIBUTES,
});

const setCurrentTab = (tab: Tab | number) => ({
	type: ConfiguratorActions.SET_CURRENT_TAB,
	payload: tab,
});

export {
	setBodyTypes,
	setModels,
	setCabinTypes,
	setChassis,
	setVechicle,
	setTabAttributes,
	clearVechicleAndAttributes,
	setCurrentTab,
	setInitiConfig,
};
