import {loadingAction} from '@actions/appActions';
import {DEFAULT_SIZE} from '@constants/pagination';
import {Store} from '@reducers/rootReducer';
import React, {Dispatch, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {toast} from 'react-toastify';
import {useLocation, useNavigate} from 'react-router-dom';
import ReactPaginate from 'react-paginate';
import styles from './Pagination.module.scss';
import {UnknownAction} from '@reduxjs/toolkit';
import queryString from 'query-string';

interface PaginationProps {
	getMaxPages: (state: Store) => number | undefined;
	request: (
		dispatch: Dispatch<UnknownAction>,
		index: number,
		size: number,
		filters?: Record<string, string | number | boolean>
	) => Promise<any>;
	pageIdentifier?: string;
}

const Pagination = (props: PaginationProps) => {
	const {request, getMaxPages, pageIdentifier = 'page'} = props;

	const [isInitial, setIsInitial] = useState<boolean>(true);

	const dispatch = useDispatch();
	const maxPages = useSelector(getMaxPages) ?? 1;

	const location = useLocation();
	const navigate = useNavigate();

	// Parse the query string to get current page and filters from URL
	const queryParams = queryString.parse(location.search);
	const currentPage = Number(queryParams[pageIdentifier]) || 1;

	// Capture filters from the query string (e.g., category, sort, etc.)
	// Assuming filter parameters are dynamic and can include multiple fields
	const filters: Record<string, string | number | boolean> = {};
	Object.keys(queryParams).forEach((key) => {
		if (key !== pageIdentifier) {
			filters[key] = queryParams[key] as string | number | boolean;
		}
	});

	// Function to update the query string with the custom page identifier and maintain filters
	const updateQueryString = (newPage: number) => {
		const updatedQuery = queryString.stringify({
			...queryParams,
			[pageIdentifier]: newPage, // Use custom page identifier
		});
		navigate(`?${updatedQuery}`, {replace: true});
	};

	// Handle page change event
	const onPageChange = async (selectedItem?: {selected: number}) => {
		const pageIndex = (selectedItem?.selected ?? 0) + 1;

		// Update URL query string
		updateQueryString(pageIndex);

		// Fetch data for the new page with filters
		dispatch(loadingAction(true));
		try {
			await request(dispatch, pageIndex, DEFAULT_SIZE, filters); // Pass filters to request
		} catch {
			toast.error('Napotkano problem w wykonaniu zadania.');
		} finally {
			dispatch(loadingAction(false));
		}
	};

	// Fetch data when query string changes
	useEffect(() => {
		if (isInitial) return setIsInitial(false);

		const fetchData = async () => {
			dispatch(loadingAction(true));
			try {
				await request(dispatch, currentPage, DEFAULT_SIZE, filters); // Pass filters to request
			} catch {
				toast.error('Napotkano problem w wykonaniu zadania.');
			} finally {
				dispatch(loadingAction(false));
			}
		};

		fetchData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location.search]); // Trigger re-fetch when the query string changes

	return (
		<div className={styles.wrapper} data-disabled={maxPages === 1}>
			<ReactPaginate
				breakLabel='...'
				nextLabel='>'
				initialPage={currentPage - 1}
				onPageChange={onPageChange}
				pageCount={maxPages}
				previousLabel='<'
				renderOnZeroPageCount={null}
			/>
		</div>
	);
};

export default Pagination;
