// Components
import AppHeader from '@components/AppHeader/AppHeader';
import SavedConfigsList from './components/SavedConfigsList/SavedConfigsList';
// Styles
import styles from './SavedConfigs.module.scss';

const SavedConfigs = () => {

	return (
		<>
			<AppHeader
				title='Zapisane konfiguracje'
				text='Możesz odtworzyć uprzednio zapisaną konfigurację pojazdu.'
				classes={styles.header}></AppHeader>
			<SavedConfigsList />
		</>
	);
};

export default SavedConfigs;
