// Components
import Navbar from './components/Navbar/Navbar';
import Topbar from './components/Topbar/Topbar';
// Types
import {Common} from '@commonTypes/main';
// Styles
import styles from './AppLayout.module.scss';

const AppLayout = (props: Common) => {
	const {children} = props;
	return (
		<>
			<Navbar />
			<div className={styles.content}>
				<Topbar />
				{children}
			</div>
			{process.env.REACT_APP_ENV !== 'production' && (
				<div className={styles.test}>Środowisko testowe</div>
			)}
		</>
	);
};

export default AppLayout;
