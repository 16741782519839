// Types
import {Store} from '@reducers/rootReducer';

const getBodyTypes = (state: Store) => state.configurator.bodyTypes;

const getModels = (state: Store) => state.configurator.models;

const getCabinTypes = (state: Store) => state.configurator.cabinTypes;

const getChassis = (state: Store) => state.configurator.chassis;

const getVechicle = (state: Store) => state.configurator.vechicle;

const getVechicleWidths = (state: Store) =>
	state.configurator.vechicle?.vechicleAvailableWidths;

const getVechicleLengths = (state: Store) =>
	state.configurator.vechicle?.vechicleAvailableLenghts;

const getVechicleHeights = (state: Store) =>
	state.configurator.vechicle?.vechicleAvailableHeights;

const getTabAttributes = (state: Store) => state.configurator.tabAttributes;

const getCurrentTab = (state: Store) => state.configurator.currentTab;

const getAvailableSizeRange = (state: Store) =>
	state.configurator.availableSizeRange;

const getAttributesDependencies = (state: Store) =>
	state.configurator.attributesDependencies;

const getInitConfig = (state: Store) => state.configurator.initConfig;

export const configuratorSelectors = {
	getBodyTypes,
	getModels,
	getCabinTypes,
	getChassis,
	getVechicle,
	getVechicleHeights,
	getVechicleWidths,
	getVechicleLengths,
	getTabAttributes,
	getCurrentTab,
	getAvailableSizeRange,
	getAttributesDependencies,
	getInitConfig,
};
