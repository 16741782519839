// Libraries
import {useState} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import classNames from 'classnames';
import {useLocation} from 'react-router-dom';
// Components
import Container from '../../../Container/Container';
// Icons
import {ReactComponent as Bodywork} from '@assets/icons/vechicle.svg';
import {ReactComponent as User} from '@assets/icons/user.svg';
import {ReactComponent as Help} from '@assets/icons/help.svg';
import {ReactComponent as Logout} from '@assets/icons/logout.svg';
import {ReactComponent as Config} from '@assets/icons/configuration.svg';
// Helpers
import Logo from '@components/Logo/Logo';
// Types
import {Tag} from '../../../../types/tags';

// Styles
import styles from './Navbar.module.scss';
import {logOutAction} from '@actions/authActions';
import {useDispatch} from 'react-redux';

const Navbar = () => {
	const {pathname} = useLocation();

	const [isExpanded, setIsExpanded] = useState(false);
	const toggleExpandHandler = () => setIsExpanded(!isExpanded);

	const navigate = useNavigate();
	const dispatch = useDispatch();

	const menuItems = [
		{
			id: 'configurator',
			label: 'Konfigurator',
			link: '/configurator',
			icon: <Config />,
		},
		{
			id: 'savedConfigs',
			label: 'Zapisane konfiguracje',
			link: '/saved-configs',
			icon: <Bodywork />,
		},
		{
			id: 'orderedConfigs',
			label: 'Lista wycen',
			link: '/ordered-configs',
			icon: <Bodywork />,
		},
		{
			id: 'users',
			label: 'Ustawienia użytkownika',
			link: '/settings',
			icon: <User />,
		},
	];

	return (
		<Container
			as={Tag.Nav}
			classes={classNames(styles.navbar, {
				[styles.navbarExpanded]: isExpanded,
			})}>
			<Link to={'/'} className={styles.logo} onClick={() => setIsExpanded(false)}>
				<Logo />
			</Link>
			<div className={styles.mobile}>
				<button
					type='button'
					onClick={toggleExpandHandler}
					className={styles.navbarButton}
					aria-label='menu'>
					<span></span>
					<span></span>
				</button>
			</div>
			<div className={styles.navbarMenu}>
				<div>
					{menuItems.map((item) => (
						<Link
							key={item.id}
							className={styles.navbarMenuItem}
							data-is-current={pathname.startsWith(item.link)}
							to={item.link}>
							<span className={styles.navbarMenuIcon}>{item.icon}</span>
							{item.label} <span className={styles.navbarMenuCurrent} />
						</Link>
					))}
				</div>
				<div className={styles.utils}>
					<Link className={styles.navbarMenuItem} to='/help'>
						<span className={styles.navbarMenuIcon}>
							<Help />
						</span>
						Pomoc <span className={styles.navbarMenuCurrent} />
					</Link>
					<button
						type='button'
						className={styles.navbarMenuItem}
						onClick={() => {
							dispatch(logOutAction());
							navigate('/login');
						}}>
						<span className={styles.navbarMenuIcon}>
							<Logout />
						</span>
						Wyloguj się
					</button>
				</div>
			</div>
		</Container>
	);
};

export default Navbar;
