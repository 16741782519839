// Components
import {ReactComponent as SavedConfigs} from '@assets/icons/saved-configs.svg';
import {ReactComponent as NewConfigs} from '@assets/icons/new-config.svg';
import {ReactComponent as History} from '@assets/icons/history.svg';
// Styles
import styles from './Home.module.scss';
import CardWithSettings from '@components/CardWithSettings/CardWithSettings';
import Container from '@components/Container/Container';
import {Tag} from '@commonTypes/tags';
//Types

const pannelRoutes = [
	{
		id: 'new',
		svg: <NewConfigs />,
		name: 'Konfigurator GRUAU',
		link: '/configurator',
		highlight: true
	},
	{
		id: 'saved',
		svg: <SavedConfigs />,
		name: 'Zapisane konfiguracje',
		link: '/saved-configs',
	},
	{
		id: 'history',
		svg: <History />,
		name: 'Historia konfiguracji',
		link: '/ordered-configs',
	},
];

const Home = () => {
	return (
		<>
			<Container as={Tag.Div} classes={styles.list}>
				{pannelRoutes?.map(({name, svg, link, id, highlight}) => (
					<CardWithSettings key={id} name={name} link={link} svg={svg} highlight={highlight}/>
				))}
			</Container>
		</>
	);
};

export default Home;
