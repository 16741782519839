// Actions
import {SavedConfigsPageActions} from '@actions/savedConfigsPageActions';
// Types
import {GetConfigListResponse, SavedConfigListItemModel} from '@api/models/configuration';
import {SavedConfigsPage} from '@actions/savedConfigsPageActions';

const initialState: SavedConfigsPage = {
	configs: null,
	currentPage: 1,
	maxPage: 1,
};

export const savedConfigsPageReducer = (
	state = initialState,
	action: {
		type: SavedConfigsPageActions;
		payload: GetConfigListResponse  | number;
	}
): SavedConfigsPage => {
	switch (action.type) {
		case SavedConfigsPageActions.SET_SAVED_CONFIGS:
			const configsRes = action.payload as GetConfigListResponse;
			return {
				...state,
				configs: configsRes.value.paginatedConfigurationList,
				maxPage: configsRes.value.totalPages,
				currentPage: configsRes.value.pageIndex,
			};
		case SavedConfigsPageActions.REMOVE_CONFIG:
			console.log(action.payload);
			return {
				...state,
				configs: state.configs?.filter(
					(item) => item.id !== (action.payload as number)
				) as SavedConfigListItemModel[],
			};
		case SavedConfigsPageActions.SET_CURRENT_PAGE:
			return {...state, currentPage: action.payload as number};
		case SavedConfigsPageActions.SET_MAX_PAGE:
			return {...state, maxPage: action.payload as number};
		default:
			return state;
	}
};
