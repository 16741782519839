import {HeightRangeModel, SizeRangeModel} from '@api/models/configuration';
import {FormField, FormKeys} from '@commonTypes/main';
import RangeInput from '@components/_FormElements/RangeInput/RangeInput';
import {Title} from '@components/_Typography';
import {TitleTag, TitleVariant} from '@components/_Typography/Title/Title';
import {initialState} from '@pages/ConfiguratorPage/Configurator';
import {Tab} from '@reducers/configuratorReducer';
import {configuratorSelectors} from '@selectors/configuratorSelectors';
import React, {useMemo} from 'react';
import {useSelector} from 'react-redux';
import styles from '../../Configurator.module.scss';

interface VehicleSizesProps {
	form: FormKeys<typeof initialState>;
	updateFormHandler: (name: string, value: FormField) => void;
}

const getRange = (
	name: string,
	range: SizeRangeModel[] | HeightRangeModel[] | undefined,
	label: string,
	required: boolean
) => {
	const noValues = range?.[0].value === 0;
	const rangeValues = range?.map((item) => item.value) ?? [] as number[];

	if (noValues) {
		if (required)
			throw new Error(name.toUpperCase() + ' can not to be required.');
		return false;
	}

	return {
		rangeValues,
		label,
		scaleLabel: 'mm',
		id: name,
		name: name,
	};
};

const VehicleSizes = (props: VehicleSizesProps) => {
	const {form, updateFormHandler} = props;

	const currentTab = useSelector(configuratorSelectors.getCurrentTab);
	const heights = useSelector(configuratorSelectors.getVechicleHeights);
	const widths = useSelector(configuratorSelectors.getVechicleWidths);
	const lengths = useSelector(configuratorSelectors.getVechicleLengths);

	const widthConfig = useMemo(
		() => getRange('width', widths, 'Szerokość', form.stepSizes.width.required),
		[form.stepSizes.width.required, widths]
	);

	const lengthConfig = useMemo(
		() => getRange('length', lengths, 'Długość', form.stepSizes.length.required),
		[form.stepSizes.length.required, lengths]
	);

	const heightConfig = useMemo(
		() => getRange('height', heights, 'Wysokość', form.stepSizes.height.required),
		[form.stepSizes.height.required, heights]
	);

	return (
		<div data-current-tab={currentTab === Tab.Sizes}>
			<Title
				tag={TitleTag.h2}
				variant={TitleVariant.section}
				classes={styles.title}>
				Wybierz wymiary wewnętrzne
			</Title>
			{lengthConfig && (
				<RangeInput
					{...lengthConfig}
					value={`${form.stepSizes.length.value}`}
					required={form.stepSizes.length.required}
					errorMessage={form.stepSizes.length.errorMessage}
					valueChangeHandler={updateFormHandler}
				/>
			)}
			{widthConfig && (
				<RangeInput
					{...widthConfig}
					value={`${form.stepSizes.width.value}`}
					required={form.stepSizes.width.required}
					errorMessage={form.stepSizes.width.errorMessage}
					valueChangeHandler={updateFormHandler}
				/>
			)}
			{heightConfig && (
				<RangeInput
					{...heightConfig}
					value={`${form.stepSizes.height.value}`}
					required={form.stepSizes.height.required}
					errorMessage={form.stepSizes.height.errorMessage}
					valueChangeHandler={updateFormHandler}
				/>
			)}
		</div>
	);
};

export default VehicleSizes;
