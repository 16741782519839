// Actions
import {
	AllAttributes,
	AttributeDependency,
	ConfiguratorActions,
	InitConfigModel,
} from '@actions/configuratorActions';
// Types
import {ConfiguratorModel} from '@actions/configuratorActions';
import {
	BodyModel,
	CabinTypeModel,
	ChassisModel,
	ModelModel,
	TabModel,
	VechicleModel,
} from '@api/models/configuration';

export enum Tab {
	Vehicle = 'vehicle',
	Sizes = 'sizes',
}

const initialState: ConfiguratorModel = {
	bodyTypes: null,
	models: null,
	cabinTypes: null,
	chassis: null,
	vechicle: null,
	tabAttributes: null,
	currentTab: Tab.Vehicle,
	availableSizeRange: false,
	attributesDependencies: {
		allAttributes: {},
		connected: {},
		excluded: {},
	},
	initConfig: null,
};

export const configuratorReducer = (
	state = initialState,
	action: {
		type: ConfiguratorActions;
		payload:
			| InitConfigModel
			| BodyModel[]
			| ModelModel[]
			| CabinTypeModel[]
			| ChassisModel[]
			| VechicleModel
			| TabModel[]
			| Tab
			| number
			| boolean;
	}
): ConfiguratorModel | undefined => {
	switch (action.type) {
		case ConfiguratorActions.SET_BODY_TYPES:
			return {
				...state,
				bodyTypes: action.payload as BodyModel[],
			};
		case ConfiguratorActions.SET_MODELS:
			return {
				...state,
				models: action.payload as ModelModel[],
			};
		case ConfiguratorActions.SET_CABIN_TYPES:
			return {
				...state,
				cabinTypes: action.payload as CabinTypeModel[],
			};
		case ConfiguratorActions.SET_CHASSIS:
			return {
				...state,
				chassis: action.payload as ChassisModel[],
			};
		case ConfiguratorActions.SET_INIT_CONFIG:
			return {
				...state,
				initConfig: action.payload as InitConfigModel,
			};
		case ConfiguratorActions.SET_VECHICLE:
			const vehicle = action.payload as VechicleModel;

			return {
				...state,
				vechicle: vehicle,
				availableSizeRange: !(
					vehicle.vechicleAvailableHeights.length === 1 &&
					vehicle.vechicleAvailableHeights[0].value === 0 &&
					vehicle.vechicleAvailableLenghts.length === 1 &&
					vehicle.vechicleAvailableLenghts[0].value === 0 &&
					vehicle.vechicleAvailableWidths.length === 1 &&
					vehicle.vechicleAvailableWidths[0].value === 0
				),
			};
		case ConfiguratorActions.SET_TAB_ATTRIBUTES:
			const tabAttributes = action.payload as TabModel[];
			const allAttributes: AllAttributes = {};
			const connected: AttributeDependency = {};
			const excluded: AttributeDependency = {};

			tabAttributes.forEach((tab) =>
				tab.categories.forEach((category) =>
					category.attributes.forEach((attribute) => {
						allAttributes[`${attribute.attributeId}`] = attribute;
						if (!!attribute.dependentAttributeIds.length)
							connected[attribute.attributeId] = attribute.dependentAttributeIds;
						if (!!attribute.excludedAttributeIds.length)
							excluded[attribute.attributeId] = attribute.excludedAttributeIds;
					})
				)
			);

			return {
				...state,
				tabAttributes,
				attributesDependencies: {
					allAttributes,
					connected,
					excluded,
				},
			};
		case ConfiguratorActions.CLEAR_VEHICLE_AND_ATTRIBUTES:
			return {
				...state,
				vechicle: null,
				tabAttributes: null,
				availableSizeRange: false,
			};
		case ConfiguratorActions.SET_CURRENT_TAB:
			return {
				...state,
				currentTab: action.payload as Tab | number,
			};
		default:
			return state;
	}
};
