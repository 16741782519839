// Helpers
import {requestCreator} from '@helpers/requsetCreator';
// Types
import {BasketRequest, BasketResponse} from './models/basket';
import {GetSavedConfigBasketResponse} from './models/basket';

export const createBasketRequest = async (
	data: BasketRequest
): Promise<BasketResponse> =>
	await requestCreator<BasketRequest, BasketResponse>(
		'PUT',
		`/basket/basket`,
		data,
		undefined
	);

	export const deleteBasketRequest = async (
	guid: string
): Promise<BasketResponse> =>
	await requestCreator<BasketRequest, BasketResponse>(
		'DELETE',
		`/basket/basket/${guid}`,
	);

// Get saved configuration detail

export const getSavedConfigBasket = async (
	configurationId: number
): Promise<GetSavedConfigBasketResponse> =>
	await requestCreator<undefined, GetSavedConfigBasketResponse>(
		'POST',
		`basket/basket-from-configuration?configurationId=${configurationId}`
	);
