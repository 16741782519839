// Libraries
import React, {FormEvent, useCallback, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {toast} from 'react-toastify';
// Components
import Input, {defaultInput} from '@components/_FormElements/Input/Input';
import PasswordCheck from '@components/_FormElements/PasswordCheck/PasswordCheck';
import Button from '@components/Button/Button';
import {Title} from '@components/_Typography';
// Helpers
import {validateForm} from '@helpers/validateForm';
// API
import {passwordChange} from '@api/auth';
// Redux
import {getUserEmail} from '@selectors/authSelectors';
// Types
import {FormField, FormKeys} from '@commonTypes/main';
import {TitleTag, TitleVariant} from '@components/_Typography/Title/Title';
import {logOutAction} from '@actions/authActions';
// Styles
import styles from './ChangePassword.module.scss';

const initialState = {
	password: {...defaultInput, required: true},
	oldPassword: {...defaultInput, required: true},
	passwordRepeat: {...defaultInput, required: true},
};

const ChangePassword = () => {
	const [form, setForm] = useState<FormKeys<typeof initialState>>(initialState);

	const dispatch = useDispatch();

	const userEmail = useSelector(getUserEmail);

	const updateFormHandler = (name: string, value: FormField) => {
		if (name === 'passwordRepeat') {
			setForm({
				...form,
				[name]: {
					...value,
					errorMessage:
						form.passwordRepeat.value !== form.password.value
							? 'Hasła do siebie nie pasują.'
							: '',
				},
			});
			return;
		}
		setForm({...form, [name]: value});
	};

	const submitHandler = useCallback(
		async (e: FormEvent) => {
			e.preventDefault();
			const isError = validateForm(form, setForm);

			if (isError) return toast.warning('Popraw formularz.');
			if (!!!userEmail) return toast.warning('Nie można wykryć adresu e-mail.');

			await passwordChange({
				login: userEmail,
				oldPassword: form.oldPassword.value,
				newPassword: form.password.value,
			})
				.then(() => {
					setForm(initialState);
					dispatch(logOutAction());
				})
				.catch(() => {});
		},
		[dispatch, form, userEmail]
	);

	return (
		<div className={styles.wrapper}>
			<Title
				tag={TitleTag.h2}
				variant={TitleVariant.standard}
				classes={styles.title}>
				Zmiana hasła
			</Title>
			<form onSubmit={submitHandler}>
				<div className={styles.formFields}>
						<Input
							id='oldPassword'
							name='oldPassword'
							type='password'
							label={'Aktualne hasło'}
							placeholder={'Wpisz aktualne hasło'}
							required={form.oldPassword.required}
							value={form.oldPassword.value}
							errorMessage={form.oldPassword.errorMessage}
							valueChangeHandler={updateFormHandler}
						/>
					<div>
						<Input
							id='password'
							name='password'
							type='password'
							label={'Nowe hasło'}
							placeholder={'Wpisz nowe hasło'}
							required={form.password.required}
							value={form.password.value}
							errorMessage={form.password.errorMessage}
							valueChangeHandler={updateFormHandler}
						/>
						<PasswordCheck password={form.password.value} />
					</div>
					<Input
						id='passwordRepeat'
						name='passwordRepeat'
						type='password'
						label={'Powtórz nowe hasło'}
						placeholder={'Wpisz ponownie nowe hasło'}
						required={form.passwordRepeat.required}
						value={form.passwordRepeat.value}
						errorMessage={form.passwordRepeat.errorMessage}
						valueChangeHandler={updateFormHandler}
					/>
				</div>
				<Button
					classes={styles.submitBtn}
					type='submit'
					disabled={!!!form.password.value || !!!form.passwordRepeat.value}>
					Zmień hasło
				</Button>
			</form>
		</div>
	);
};

export default ChangePassword;
