// Libraries
import React, {ChangeEvent} from 'react';
import classNames from 'classnames';
// Components
import Info, {InfoProps} from '../Info/Info';
// Helpers
import validation from '@helpers/validation';
// Types
import {FormField} from '@commonTypes/main';
// Styles
import styles from './Checkbox.module.scss';

export interface CheckboxProps {
	id: string;
	name: string;
	label: string;
	type?: 'checkbox' | 'radio';
	required: boolean;
	value: boolean;
	errorMessage?: string;
	isValid?: boolean;
	valueChangeHandler: (name: string, value: FormField) => void;
	classes?: string;
	toggler?: boolean;
	info?: InfoProps;
	disabled?: boolean;
}

const Checkbox = (props: CheckboxProps) => {
	const {
		id,
		name,
		label,
		type = 'checkbox',
		required = false,
		value,
		errorMessage,
		valueChangeHandler,
		classes,
		toggler,
		info,
		disabled,
	} = props;

	const changeHandler = (e: ChangeEvent<HTMLInputElement>) => {
		if (disabled) return;
		const errorMessage = validation(true, e.target.checked, name, required);
		valueChangeHandler(name, {
			value: type === 'radio' ? id : e.target.checked,
			errorMessage,
			isValid: errorMessage === '',
			required: required,
		});
	};

	return (
		<div
			className={styles.wrapper}
			data-has-info={!!info}
			data-disabled={disabled}>
			<label
				className={classNames(styles.checkbox, classes)}
				data-is-error={!!errorMessage}
				data-is-toggler={!!toggler}>
				<input
					id={id}
					name={name}
					className={styles.checkboxField}
					type={type}
					onChange={changeHandler}
					checked={value}
				/>
				<span className={styles.checkboxLabel}>
					{label}
					{required && ' *'}
				</span>
			</label>
			{!!info && <Info {...info} />}
		</div>
	);
};

export default Checkbox;
