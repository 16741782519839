// Liobraries
import {FormEvent, useCallback, useMemo, useState} from 'react';
// Components
import Container from '@components/Container/Container';
import {Title} from '@components/_Typography';
import Button, {ButtonTag, ButtonVariant} from '@components/Button/Button';
import Logo from '@components/Logo/Logo';
import Input, {defaultInput} from '@components/_FormElements/Input/Input';
// Types
import {Tag} from '@commonTypes/tags';
import {TitleTag, TitleVariant} from '@components/_Typography/Title/Title';
// API
// Types
import {FormField, FormKeys} from '@commonTypes/main';
// Styles
import styles from './PasswordSetup.module.scss';
import {toast} from 'react-toastify';
import {validateForm} from '@helpers/validateForm';
import {passwordResetSetup} from '@api/auth';
import {FormSteps} from '@components/ContactForm/ContactForm';
import Loader from '@components/Loader/Loader';
import SuccessStep from '@components/ContactForm/Steps/SuccessStep';
import ErrorStep from '@components/ContactForm/Steps/ErrorStep';
import PasswordCheck from '@components/_FormElements/PasswordCheck/PasswordCheck';
import {useSearchParams} from 'react-router-dom';

const initialState = {
	email: {...defaultInput, required: true},
	password: {...defaultInput, required: true},
	passwordRepeat: {...defaultInput, required: true},
};

const PasswordSetup = () => {
	const [searchParams] = useSearchParams();

	const [form, setForm] = useState<FormKeys<typeof initialState>>(initialState);
	const [step, setStep] = useState<FormSteps>(FormSteps.Form);

	const updateFormHandler = (name: string, value: FormField) => {
		if (name === 'passwordRepeat') {
			setForm({
				...form,
				[name]: {
					...value,
					errorMessage:
						form.passwordRepeat.value !== form.password.value
							? 'Hasła do siebie nie pasują.'
							: '',
				},
			});
			return;
		}
		setForm({...form, [name]: value});
	};

	const submitHandler = useCallback(
		async (e: FormEvent) => {
			e.preventDefault();
			const isError = validateForm(form, setForm);

			const id = searchParams.get('id');

			if (isError) return toast.warning('Popraw formularz.');
			if (!!!id) return toast.warning('Link resetu hasła jest nieaktywny.');

			setStep(FormSteps.Sending);

			await passwordResetSetup({
				email: form.email.value,
				newPassword: form.password.value,
				guid: id,
			})
				.then(() => {
					setStep(FormSteps.Success);
					setForm(initialState);
				})
				.catch(() => {
					setStep(FormSteps.Error);
				});
		},
		[form, searchParams]
	);

	const currentStep = useMemo(() => {
		switch (step) {
			case FormSteps.Form:
				return;
			case FormSteps.Sending:
				return <Loader title='Ustawianie nowego hasła.' />;
			case FormSteps.Success:
				return (
					<>
						<SuccessStep title={<span>Ustawiono nowe hasło!</span>} />
						<Button
							as={ButtonTag.InternalLink}
							variant={ButtonVariant.Primary}
							classes={styles.backToLogin}
							href='/login'>
							Powrót do logowania
						</Button>
					</>
				);
			case FormSteps.Error:
				return (
					<>
						<ErrorStep />
						<Button
							variant={ButtonVariant.Primary}
							classes={styles.backToLogin}
							onClick={() => {
								setStep(FormSteps.Form);
							}}>
							Spróbuj jeszcze raz
						</Button>
					</>
				);
			default:
				throw new Error('Unknown ContactForm step.');
		}
	}, [step]);

	return (
		<Container as={Tag.Main} classes={styles.wrapper}>
			<section className={styles.formWrapper}>
				<Logo />
				<Title
					tag={TitleTag.h1}
					variant={TitleVariant.section}
					classes={styles.title}>
					Ustaw nowe hasło
				</Title>
				<form onSubmit={submitHandler}>
					{step === FormSteps.Form ? (
						<>
							<div className={styles.fieldsWrapper}>
								<Input
									id='email'
									name='email'
									type='email'
									label={'E-mail'}
									placeholder={'Wpisz e-mail'}
									required={form.email.required}
									value={form.email.value}
									errorMessage={form.email.errorMessage}
									valueChangeHandler={updateFormHandler}
								/>
								<div>
									<Input
										id='password'
										name='password'
										type='password'
										label={'Hasło'}
										placeholder={'Wpisz hasło'}
										required={form.password.required}
										value={form.password.value}
										errorMessage={form.password.errorMessage}
										valueChangeHandler={updateFormHandler}
									/>
									<PasswordCheck password={form.password.value} />
								</div>
								<Input
									id='passwordRepeat'
									name='passwordRepeat'
									type='password'
									label={'Powtórz hasło'}
									placeholder={'Wpisz ponownie hasło'}
									required={form.passwordRepeat.required}
									value={form.passwordRepeat.value}
									errorMessage={form.passwordRepeat.errorMessage}
									valueChangeHandler={updateFormHandler}
								/>
							</div>
							<div className={styles.buttons}>
								<Button classes={styles.submitBtn} type='submit' fullWidth>
									Zresetuj hasło
								</Button>
								<Button
									as={ButtonTag.InternalLink}
									variant={ButtonVariant.OutlineWithBorder}
									classes={styles.submitBtn}
									href='/login'>
									Anuluj
								</Button>
							</div>
						</>
					) : (
						currentStep
					)}
				</form>
			</section>
		</Container>
	);
};

export default PasswordSetup;
