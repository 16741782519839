import { GetConfigListResponse, SavedConfigListItemModel } from "@api/models/configuration";

export interface SavedConfigsPage {
	configs: SavedConfigListItemModel[] | null;
	currentPage: number;
	maxPage: number;
}

export enum SavedConfigsPageActions {
	SET_SAVED_CONFIGS = 'SET_SAVED_CONFIGS',
	REMOVE_CONFIG = 'REMOVE_CONFIG',
	SET_CURRENT_PAGE = 'SET_CURRENT_PAGE',
	SET_MAX_PAGE = 'SET_MAX_PAGE',
}

const setSavedConfigs = (configsRes: GetConfigListResponse) => ({
	type: SavedConfigsPageActions.SET_SAVED_CONFIGS,
	payload: configsRes,
});

const removeConfig = (configId: number) => ({
	type: SavedConfigsPageActions.REMOVE_CONFIG,
	payload: configId,
});

const setTabsCurrentPage = (currentPage: number) => ({
	type: SavedConfigsPageActions.SET_CURRENT_PAGE,
	payload: currentPage,
});

const setTabsMaxPage = (maxPage: number) => ({
	type: SavedConfigsPageActions.SET_MAX_PAGE,
	payload: maxPage,
});

export {setSavedConfigs, removeConfig, setTabsCurrentPage, setTabsMaxPage};
