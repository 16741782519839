// Libraires
import React from 'react';
// Components
import Container from '../Container/Container';
import {Title, Text} from '@typography/index';
// Types
import {TitleTag, TitleVariant} from '@typography/Title/Title';
import {Tag} from '@commonTypes/tags';
import {Common} from '@commonTypes/main';
// Styles
import styles from './AppHeader.module.scss';
import classNames from 'classnames';

interface AppHeaderProps extends Common {
	title: string;
	text: string;
}

const AppHeader = (props: AppHeaderProps) => {
	const {title, text, children, classes} = props;

	return (
		<Container as={Tag.Header} classes={classNames(styles.header, classes)}>
			<div>
				<Title
					classes={styles.headerTitle}
					tag={TitleTag.h1}
					variant={TitleVariant.section}>
					{title}
				</Title>
				<Text>{text}</Text>
			</div>
			{children}
		</Container>
	);
};

export default AppHeader;
