import {Tag} from '@commonTypes/tags';
import {Title} from '@components/_Typography';
import {TitleTag, TitleVariant} from '@components/_Typography/Title/Title';
import Container from '@components/Container/Container';
import Logo from '@components/Logo/Logo';
import styles from './TermsOfUse.module.scss';
import Button from '@components/Button/Button';
import {defaultInput} from '@components/_FormElements/Input/Input';
import {FormField, FormKeys} from '@commonTypes/main';
import Checkbox from '@components/_FormElements/Checkbox/Checkbox';
import {useCallback, useState} from 'react';

export const initialState = {
	remember: {
		...defaultInput,
		required: true,
		value: false,
	},
};

const TermsOfUse = () => {
	const [form, setForm] = useState<FormKeys<typeof initialState>>(initialState);

	const updateFormHandler = useCallback(
		(name: string, value: FormField) => {
			setForm({...form, [name]: value});
		},
		[form]
	);

	const onSubmit = useCallback(() => {
		if (form.remember.value) {
			localStorage.setItem('termsOfUse', 'true');
		}
		sessionStorage.setItem('termsOfUse', 'true');

		window.location.reload()
	}, [form.remember.value]);

	return (
		<Container as={Tag.Section} classes={styles.wrapper}>
			<Logo />
			<Title tag={TitleTag.h1} variant={TitleVariant.main} classes={styles.title}>
				Regulamin korzystania z Konfiguratora Gruau Polska
			</Title>
			<ol className={styles.mainList}>
				<li>
					Postanowienia ogólne
					<ol>
						<li>
							Regulamin określa zasady korzystania z usługi pod nazwą Konfigurator
							Gruau Polska.
						</li>
						<li>
							Usługa Konfigurator Gruau Polska dostępna na stronie:
							https://client.gruau.pl polega na udostępnieniu za pośrednictwem strony
							internetowej przez Gruau Polska sp. z o.o. z siedzibą w Daszynie (99 -
							107), Daszyna 29 a, wpisaną do Krajowego Rejestru Sądowego - Rejestru
							Przedsiębiorców prowadzonego przez Sąd Rejonowy dla Łodzi - Śródmieścia w
							Łodzi pod numerem KRS 0000143380, kapitał zakładowy 4.900.000 zł, BDO
							000284060 (dalej Gruau Polska) narzędzia poglądowego, które umożliwia
							określenie szacunkowej wyceny zabudowy pojazdu.
						</li>
						<li>
							Warunkiem technicznym korzystania z Konfiguratora Gruau Polska jest
							posiadanie urządzenia z dostępem do sieci Internet, wyposażonego w
							przeglądarkę internetową oraz posiadanie konta poczty elektronicznej.
						</li>
						<li>
							Usługa Konfiguratora Gruau Polska skierowana jest wyłącznie do
							Koncesjonerów.
						</li>
					</ol>
				</li>

				<li>
					Definicje
					<ol>
						<li>
							Konfigurator Gruau Polska - narzędzie służące do sporządzania przez
							Koncesjonerów szacunkowych wycen na zabudowy pojazdów samochodowych
							wykonywane przez Gruau Polska, dostępne pod adresem strony internetowej:
							https://client.gruau.pl
						</li>
						<li>
							Koncesjoner - podmiot, który w ramach prowadzonej działalności
							gospodarczej zajmuje się sprzedażą określonych marek pojazdów
							samochodowych,
						</li>
						<li>
							Zabudowa - przystosowanie danego seryjnie produkowanego pojazdu
							samochodowego do pełnienia określonej funkcji w ramach opcji dostępnych w
							bieżącej ofercie handlowej Gruau Polska,
						</li>
						<li>
							Użytkownik – przedstawiciel Koncesjonera (pracownik lub osoba
							współpracująca z Koncesjonerem na innej podstawie prawnej) korzystający z
							Konfiguratora,
						</li>
						<li>
							Konto - prowadzony dla Użytkownika pod unikalną nazwą (login) zbiór
							zasobów, w którym gromadzone są dane Użytkownika oraz informacje o jego
							działaniach w Konfiguratorze,
						</li>
						<li>
							Login – służbowy adres poczty elektronicznej (z domeny Koncesjonera)
							podany przy rejestracji Konta,
						</li>
						<li>
							Hasło – unikalne hasło utworzone przez użytkownika składające się z
							minimum 8 znaków, w tym: dużej litery, małej litery, cyfry i znaku
							interpunkcyjnego.
						</li>
					</ol>
				</li>

				<li>
					Ogólne warunki korzystania z Konfiguratora
					<ol>
						<li>
							Skorzystanie z usługi Konfigurator Gruau Polska umożliwia wyłącznie
							poglądowe skonfigurowanie Zabudowy, w oparciu o dane wprowadzone przez
							Użytkownika, w wyniku czego powstaje wstępna szacunkowa wycena
							potencjalnej Zabudowy Gruau Polska.
						</li>
						<li>
							Wszelkie informacje znajdujące się na stronie Konfiguratora Gruau Polska
							https://client.gruau.pl, w tym wycena sporządzona w Konfiguratorze w
							oparciu o wpisane przez Użytkownika dane, nie stanowią oferty w
							rozumieniu Kodeksu Cywilnego, przedstawiają poglądową konfigurację
							Zabudowy i jej szacunkową wycenę, które stanowią jedynie zaproszenie do
							zawarcia umowy, w żadnym razie nie zobowiązują Gruau Polska do zawarcia
							umowy na warunkach wynikających z wykonanej za pomocą Konfiguratora
							szacunkowej wyceny. Zawarcie umowy na wykonanie przez Gruau Polska
							Zabudowy wymaga złożenia odrębnego zapytania za pośrednictwem poczty
							elektronicznej na adresy dostępne na stronie internetowej Gruau Polska z
							powołaniem się na wycenę sporządzoną w oparciu o Konfigurator, a
							następnie, po otrzymaniu od Gruau Polska ostatecznej wyceny zawierającej
							aktualne warunki handlowe Gruau Polska, złożenia zamówienia na otrzymanym
							druku wyceny.
						</li>
						<li>
							Gruau Polska nie odpowiada za prawidłowość wykonanej przez Użytkownika za
							pośrednictwem Konfiguratora wyceny, a także zastrzega, że wizualizacja
							(zdjęcia) poszczególnych elementów zamieszczonych w Konfiguratorze Gruau
							Polska wykorzystanych do konfiguracji Zabudowy a także ich masy mają
							charakter poglądowy, ich użycie nie oznacza, że dokładnie takie elementy
							i o takiej samej masie zostaną wykorzystane w danym modelu pojazdu
							samochodowego do jego Zabudowy.
						</li>
						<li>
							Gruau Polska zastrzega sobie prawo do dokonywania zmiany warunków i cen
							Zabudów dostępnych w Konfiguratorze Gruau Polska oraz wprowadzania nowych
							rodzajów i opcji Zabudów, a także wycofywania dotychczasowych.
						</li>
						<li>Usługa Konfigurator jest dostępna nieodpłatnie.</li>
						<li>
							Gruau Polska nie ponosi odpowiedzialności za:
							<ol>
								<li>ciągłość działania Konfiguratora Gruau Polska;</li>
								<li>prawidłowość wykonanej w Konfiguratorze Gruau Polska wyceny;</li>
								<li>
									dostępność danej Zabudowy lub poszczególnych jej elementów w bieżącej
									ofercie handlowej Gruau Polska;
								</li>
							</ol>
						</li>
						<li>
							Autorskie prawa majątkowe do Konfiguratora Gruau Polska i
							wykorzystywanych w nim elementów oraz prawa własności przemysłowej, w tym
							prawa ochronne na znaki towarowe Zabudowy i poszczególnych jej elementów
							umieszczone w Konfiguratorze, przysługują Gruau Polska lub podmiotom
							trzecim i podlegają ochronie prawnej.
						</li>
						<li>
							Użytkownik nie jest uprawniony do kopiowania, rozpowszechniania,
							reprodukowania jakichkolwiek treści, w tym danych umieszczonych w
							Konfiguratorze Gruau Polska w innym celu niż związany ze złożeniem
							zamówienia na zabudowę w Gruau Polska, w żadnym wypadku nie jest
							uprawniony do modyfikowania ich treści i dalszego posługiwania się ich
							treścią po zmodyfikowaniu.
						</li>
						<li>
							Użytkownik jest zobowiązany korzystać z Konfiguratora w sposób zgodny z
							zasadami współżycia społecznego oraz obowiązującymi przepisami prawa,
							zakazane jest dostarczanie przez Użytkownika treści o charakterze
							bezprawnym.
						</li>
					</ol>
				</li>

				<li>
					Rejestracja i Konto Użytkownika
					<ol>
						<li>
							Korzystanie z Konfiguratora Gruau Polska wymaga rejestracji – założenia
							przez Użytkownika Konta.
						</li>
						<li>
							Rejestracja w Konfiguratorze Gruau Polska następuje poprzez wypełnienie
							przez Użytkownika formularza rejestracyjnego, udostępnionego w panelu
							rejestracyjnym za pomocą wysłanego linku na stronę
							https://client.gruau.pl/, wymagającego podania następujących danych
							Użytkownika:
							<ol>
								<li>imię i nazwisko,</li>
								<li>adres służbowej poczty elektronicznej (z domeny Koncesjonera),</li>
								<li>numer służbowego telefonu,</li>
								<li>nazwy koncesjonera,</li>
							</ol>
							oraz ustawienia własnego hasła i zaakceptowania warunków niniejszego
							Regulaminu.
						</li>
						<li>
							Podczas Rejestracji, jak i późniejszego korzystania z Konta, Użytkownik
							zobowiązany jest podawać wymagane i prawdziwe dane.
						</li>
						<li>
							Aktywowanie konta wymaga potwierdzenia ze strony Gruau Polska. O
							aktywowaniu konta Użytkownik otrzyma powiadomienie na adres poczty
							elektronicznej podany przy rejestracji.
						</li>
						<li>
							W przypadku zmian niniejszego Regulaminu, pierwszorazowe korzystanie z
							Konfiguratora Gruau Polska po ich wprowadzeniu wymaga ponownego
							potwierdzenia przez Użytkownika akceptacji Regulaminu. W przypadku braku
							akceptacji zmiany Regulaminu, Użytkownik nie będzie miał możliwości
							dalszego korzystania z Konfiguratora Gruau.
						</li>
						<li>
							Dany Użytkownik może posiadać tylko jedno Konto. Zakazuje się korzystania
							z cudzych Kont oraz udostępniania kont osobom trzecim.
						</li>
					</ol>
				</li>

				<li>
					Dane osobowe
					<ol>
						<li>
							Administratorem danych osobowych Użytkownika jest Gruau Polska sp. z o.o.
							z siedzibą w Daszynie (99 - 107), Daszyna 29 a. tel. 24 389 09 00,
							e-mail: gruau@gruau.pl. Dane osobowe Użytkowników, przetwarzane są w celu
							świadczenia Użytkownikom przez Gruau Polska usług związanych z
							udostępnieniem Konfiguratora Gruau Polska. Podanie danych jest
							dobrowolne, jednak niezbędne do zrealizowania celu jakim jest korzystanie
							z Konfiguratora Gruau Polska. Przetwarzanie danych jest dokonywane od
							momentu rejestracji Konta do czasu upływu okresu 3 lat od końca roku, w
							którym konto zostało zlikwidowane. <br />
							<br />
							Kategoriami odbiorców, którym Gruau Polska może udostępnić dane osobowe
							Użytkowników są podmioty świadczące nadzór nad oprogramowaniem, obsługę
							poczty elektronicznej oraz inne podmioty, z którymi Gruau Polska
							współpracuje w zakresie usługi Konfiguratora Gruau Polska.
							<br />
							<br />
							Użytkownicy posiadają możliwość dostępu i aktualizacji podanych danych, z
							wyjątkiem adresu e-mail podanego przy rejestracji.
							<br />
							<br />
							Użytkownicy mogą skorzystać z przysługujących praw kontaktując się z
							Administratorem przez powyższe dane kontaktowe. Użytkownikom przysługuje
							prawo wniesienia skargi do Prezesa Urzędu Ochrony Danych Osobowych. W
							ramach realizowanego przetwarzania nie występuje profilowanie.
							<br />
							<br />
							Dane nie będą przekazywane do Państw trzecich poza EOG.
						</li>
						<li>
							Gruau Polska gromadzi w ramach usługi Konfiguratora dane osobowe za
							pośrednictwem powszechnie dostępnych technologii. Gruau Polska stosuje
							adekwatne środki techniczne i organizacyjne zapewniające ochronę
							przetwarzanych danych, w tym zabezpieczenia o charakterze
							programistycznym. Gruau Polska zabezpiecza dane przed ich udostępnieniem
							osobom nieupoważnionym, a także innymi przypadkami ich ujawnienia bądź
							utraty oraz przed ich zniszczeniem, nieuprawnioną modyfikacją lub
							przetwarzaniem z naruszeniem obowiązujących przepisów prawa.
						</li>
					</ol>
				</li>

				<li>
					Zaprzestanie świadczenia usług
					<ol>
						<li>
							Gruau Polska ma prawo zablokować konto Użytkownika w Konfiguratorze w
							trybie natychmiastowym, w przypadku rażącego naruszenia przez Użytkownika
							przepisów prawa lub postanowień niniejszego Regulaminu a także powzięcia
							informacji o zakończeniu współpracy Użytkownika z Koncesjonerem. O
							zablokowaniu konta Użytkownik otrzyma powiadomienie na adres poczty
							elektronicznej przypisany do Konta danego Użytkownika.
						</li>
						<li>
							Użytkownik, jeżeli chce zrezygnować z korzystania z Konta może tego
							dokonać w każdym czasie zgłaszając to do Gruau Polska za pośrednictwem
							poczty elektronicznej na adres: konfigurator@gruau.pl. Likwidacja konta
							zostanie potwierdzona powiadomieniem na adres poczty elektronicznej
							Użytkownika przypisany do Konta danego Użytkownika w terminie 48 godz.
						</li>
					</ol>
				</li>

				<li>
					Zasady Odpowiedzialności
					<ol>
						<li>
							Gruau Polska nie ponosi odpowiedzialności z tytułu jakiejkolwiek szkody
							bezpośredniej lub pośredniej związanej z korzystaniem przez Użytkownika z
							Konfiguratora, w tym z tytułu utraty danych umieszczonych w
							Konfiguratorze przez Użytkownika, a także z tytułu podjętych przez
							Użytkownika i Koncesjonera zobowiązań wobec osób trzecich.
						</li>
						<li>
							Gruau Polska nie ponosi odpowiedzialności za brak możliwości skorzystania
							przez Użytkownika z Konfiguratora, za opóźnienia w zaktualizowaniu cen i
							innych informacji zamieszczonych w Konfiguratorze, ani za szkody wynikłe
							z ewentualnych opóźnień lub nieprawidłowości w połączeniu z
							Konfiguratorem.
						</li>
					</ol>
				</li>

				<li>
					Postępowanie reklamacyjne
					<ol>
						<li>
							W przypadku, gdy zdaniem Użytkownika usługi świadczone na podstawie
							Regulaminu są realizowane niezgodnie z postanowieniami Regulaminu, może
							on złożyć zastrzeżenia w sposób określony poniżej (Reklamacja).
						</li>
						<li>
							Reklamacje dotyczące korzystania z usług Konfiguratora Gruau można
							składać w formie elektronicznej, za pośrednictwem poczty elektronicznej
							na adres: konfigurator@gruau.pl wskazując dane Użytkownika zgodne z
							podanymi na Koncie Użytkownika oraz opis zgłaszanych zastrzeżeń.
						</li>
						<li>
							Odpowiedź na reklamację zostanie udzielona w terminie 30 dni na adres
							e-mail przypisany do Konta danego Użytkownika. W przypadku, gdy
							Reklamacja nie zawiera informacji koniecznych do jej rozpatrzenia,
							składający Reklamację zostanie poproszony o jej uzupełnienie w niezbędnym
							zakresie, a termin na udzielenie odpowiedzi na Reklamację biegnie wówczas
							od dnia doręczenia uzupełnionej Reklamacji.
						</li>
					</ol>
				</li>

				<li>
					Postanowienia końcowe
					<ol>
						<li>
							Gruau Polska jest uprawniona do zmiany Regulaminu w każdym czasie, w
							szczególności z uwagi na zmiany przepisów prawa, zmiany cennika,
							usprawnienie działania Konfiguratora i obsługi Użytkowników, poprawę
							ochrony prywatności Użytkowników, zapobieganie nadużyciom, względy
							bezpieczeństwa, zmiany technologiczne i funkcjonalne, zmiany w zakresie
							świadczonych usług, w tym wprowadzanie nowych, zmiany redakcyjne.
						</li>
						<li>
							Zmiany Regulaminu wchodzą w życie w terminie 7 dni, od dnia opublikowania
							zmian lub nowego Regulaminu.
						</li>
						<li>
							We wszelkich sprawach nieuregulowanych w niniejszym Regulaminie
							zastosowanie znajdą przepisy kodeksu cywilnego, ustawy o świadczeniu
							usług drogą elektroniczną, oraz innych ustaw prawa polskiego.
						</li>
						<li>
							Wszelkie spory pomiędzy Gruau Polska i Użytkownikiem, dotyczące usługi
							Konfiguratora Gruau Polska podlegają rozstrzygnięciu przez polskie sądy
							powszechne właściwe według siedziby Gruau Polska.
						</li>
					</ol>
				</li>
			</ol>
			<div className={styles.form}>
				<Button type='button' onClick={onSubmit}>
					Akceptuję
				</Button>

				<Checkbox
					id={'remember'}
					name={`remember`}
					label={`Zapamiętaj mój wybór.`}
					required={false}
					value={form.remember.value}
					valueChangeHandler={updateFormHandler}
					toggler
				/>
			</div>
		</Container>
	);
};

export default TermsOfUse;
