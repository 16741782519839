// Components
import AppHeader from '@components/AppHeader/AppHeader';
import Container from '@components/Container/Container';
import ContactForm from '@components/ContactForm/ContactForm';
// Types
import {Tag} from '@commonTypes/tags';
// Styles
import styles from './Help.module.scss';

const Help = () => {
	return (
		<>
			<AppHeader
				title='Potrzebujesz pomocy?'
				text='Skontaktuj się z administratorem za pomocą formularza.'
			/>
			<Container as={Tag.Section} classes={styles.wrapper}>
				<ContactForm />
			</Container>
		</>
	);
};

export default Help;
