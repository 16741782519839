import {setCurrentTab} from '@actions/configuratorActions';
import {Tab} from '@reducers/configuratorReducer';
import {configuratorSelectors} from '@selectors/configuratorSelectors';
import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import styles from './Tabs.module.scss';

const Tabs = () => {
	const tabAttributes = useSelector(configuratorSelectors.getTabAttributes);
	const availableSizeRange = useSelector(
		configuratorSelectors.getAvailableSizeRange
	);
	const currentTab = useSelector(configuratorSelectors.getCurrentTab)

	const dispatch = useDispatch();

	const changeTab = (tabId: Tab | number) => dispatch(setCurrentTab(tabId));

	return (
		<ul className={styles.list}>
			<li className={styles.item} data-current={currentTab === Tab.Vehicle}>
				<button onClick={() => changeTab(Tab.Vehicle)}>Wybór Pojazdu</button>
			</li>
			{availableSizeRange && (
				<li className={styles.item} data-current={currentTab === Tab.Sizes}>
					<button onClick={() => changeTab(Tab.Sizes)}>Wymiary wewnętrzne</button>
				</li>
			)}
			{tabAttributes?.map((tab) => (
				<li className={styles.item} key={tab.tabId} data-current={currentTab === tab.tabId}>
					<button onClick={() => changeTab(tab.tabId)}>{tab.tabName}</button>
				</li>
			))}
		</ul>
	);
};

export default Tabs;
