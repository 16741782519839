import React, {FormEvent, useCallback, useMemo} from 'react';
import {defaultInput} from '@components/_FormElements/Input/Input';
import {useState} from 'react';
import {FormField, FormKeys} from '@commonTypes/main';
import Form from './Steps/Form';

import styles from './ContactForm.module.scss';
import Loader from '@components/Loader/Loader';
import ErrorStep from './Steps/ErrorStep';
import SuccessStep from './Steps/SuccessStep';
import {validateForm} from '@helpers/validateForm';
import {toast} from 'react-toastify';

export const initialState = {
	type: {
		...defaultInput,
		required: true,
		value: '0' as undefined | string,
	},
	description: {...defaultInput, required: true},
};

export enum FormSteps {
	Form,
	Sending,
	Success,
	Error,
}

const ContactForm = () => {
	const [step, setStep] = useState<FormSteps>(FormSteps.Form);
	const [form, setForm] = useState<FormKeys<typeof initialState>>(initialState);

	const updateFormHandler = useCallback(
		(name: string, value: FormField) => {
			setForm({...form, [name]: value});
		},
		[form]
	);

	const submitHandler = useCallback(
		(e: FormEvent) => {
			e.preventDefault();

			const isError = validateForm(form, setForm);
			if (isError) return toast.warning('Fill in form.');

			setStep(FormSteps.Sending);
			fetch(`https://formspree.io/f/${process.env.REACT_APP_FORM_SPREE}`, {
				method: 'POST',
				body: JSON.stringify({
					type: form.type.value,
					message: form.description.value,
				}),
				headers: {
					Accept: 'application/json',
				},
			})
				.then((response) => {
					if (response.ok) {
						setStep(FormSteps.Success);
						setForm(initialState);
					} else {
            setStep(FormSteps.Error);
						toast.error('E-mail sending failed');
					}
				})
				.catch((error) => {
					setStep(FormSteps.Error);
					toast.error('E-mail sending failed');
				});
		},
		[form, setForm, setStep]
	);

	const currentStep = useMemo(() => {
		switch (step) {
			case FormSteps.Form:
				return (
					<Form
						updateFormHandler={updateFormHandler}
						form={form}
						submitHandler={submitHandler}
					/>
				);
			case FormSteps.Sending:
				return <Loader title='Trwa wysyłanie wiadomości' />;
			case FormSteps.Success:
				return <SuccessStep />;
			case FormSteps.Error:
				return <ErrorStep />;
			default:
				throw new Error('Unknown ContactForm step.');
		}
	}, [form, step, submitHandler, updateFormHandler]);

	return <div className={styles.contact}>{currentStep}</div>;
};

export default ContactForm;
