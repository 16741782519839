import React, {useCallback, useState} from 'react';
// Components
import Modal from '@components/Modal/Modal';
import {Text} from '@components/_Typography';
// Assets
import {ReactComponent as InfoSvg} from '@assets/icons/info.svg';
import defaultImage from '@assets/images/empty-image.png';
// Styles
import styles from './Info.module.scss';

export interface InfoProps {
	title: string;
	imageUrl: string;
	description: string;
}

const Info = (props: InfoProps) => {
	const {title, imageUrl, description} = props;

	const [modal, setModal] = useState<boolean>(false);
	const [imgSrc, setImgSrc] = useState<string>(imageUrl);

	const toggleModal = useCallback(() => setModal(!modal), [modal]);

	const handleImageError = () => {
		setImgSrc(defaultImage);
	};

	return (
		<>
			<button className={styles.button} type='button' onClick={toggleModal}>
				{imageUrl ? (
					<img src={imgSrc} alt={title} onError={handleImageError} />
				) : (
					<InfoSvg />
				)}
			</button>
			{modal && (
				<Modal closeHandler={toggleModal} title={title}>
					{imgSrc && (
						<div className={styles.image}>
							<img
								src={imgSrc}
								alt={title}
								onError={handleImageError}
							/>
						</div>
					)}
					<Text>{description}</Text>
				</Modal>
			)}
		</>
	);
};

export default Info;
