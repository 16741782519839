// Libraries
import {combineReducers} from 'redux';
// Reducers
import {authReducer} from './authReducer';
import {configuratorReducer} from './configuratorReducer';
import {savedConfigsPageReducer} from './savedConfigsPageReducer';
import {orderedConfigsPageReducer} from './orderedConfigsPageReducer';
import {basketReducer} from './basketReducer';
import {appReducer} from './appReducer';
import {previewOfferPageReducer} from './previewOfferReducer';
// Types
import {UserDetailsModel} from '@api/models/authModels';
import {ConfiguratorModel} from '@actions/configuratorActions';
import {SavedConfigsPage} from '@actions/savedConfigsPageActions';
import {OrderedConfigsPage} from '@actions/orderedConfigsPageActions';
import {BasketState} from '@actions/basketActions';
import {AppState} from '@actions/appActions';
import {PreviewOfferPage} from '@actions/previewOfferActions';

export interface Store {
	app: Partial<AppState>;
	auth: Partial<UserDetailsModel>;
	basket: Partial<BasketState>;
	configurator: Partial<ConfiguratorModel>;
	savedConfigsPage: Partial<SavedConfigsPage>;
	orderedConfigsPage: Partial<OrderedConfigsPage>;
	previewOfferPage: Partial<PreviewOfferPage>;
}

export default combineReducers({
	app: appReducer,
	auth: authReducer,
	basket: basketReducer,
	configurator: configuratorReducer,
	savedConfigsPage: savedConfigsPageReducer,
	orderedConfigsPage: orderedConfigsPageReducer,
	previewOfferPage: previewOfferPageReducer,
} as Store);
