import React, {useState} from 'react';
import {getBasket} from '@selectors/basketSelectors';
import {useDispatch, useSelector} from 'react-redux';
import styles from './BasketSummary.module.scss';
import Button, {ButtonVariant} from '@components/Button/Button';
import {saveConfigurationRequest} from '@api/configuration';
import {toast} from 'react-toastify';
import {loadingAction} from '@actions/appActions';
import BasketContent from './BasketContent';
import {setCurrentTab} from '@actions/configuratorActions';
import {Tab} from '@reducers/configuratorReducer';
import {createOfferRequest} from '@api/offer';
import Input, {defaultInput} from '@components/_FormElements/Input/Input';
import {FormField, FormKeys} from '@commonTypes/main';
import {validateForm} from '@helpers/validateForm';

interface BasketSummaryProps {
	clearForm: (deleteBasket?: boolean) => void;
	closeModalHandler: () => void;
}

const initialState = {
	name: {...defaultInput, required: true},
};

const BasketSummary = (props: BasketSummaryProps) => {
	const {clearForm, closeModalHandler} = props;
	const [form, setForm] = useState<FormKeys<typeof initialState>>(initialState);

	const dispatch = useDispatch();
	const basket = useSelector(getBasket);

	const updateFormHandler = (name: string, value: FormField) => {
		setForm({...form, [name]: value});
	};

	const saveHandler = async () => {
		if (!!!basket?.id)
			return toast.error('Nie udało się odnaleźć ID konfiguracji.');

		const isError = validateForm(form, setForm);
		if (isError) return toast.warning('Popraw formularz.');

		dispatch(setCurrentTab(Tab.Vehicle));
		closeModalHandler();
		dispatch(loadingAction(true));
		await saveConfigurationRequest(basket?.id, form.name.value).finally(() => {
			dispatch(loadingAction(false));
		});

		clearForm();
	};

	const orderHandler = async () => {
		if (!!!basket?.id) return;

		const isError = validateForm(form, setForm);
		if (isError) return toast.warning('Popraw formularz.');

		dispatch(loadingAction(true));
		await createOfferRequest(basket.id, form.name.value);
		dispatch(setCurrentTab(Tab.Vehicle));
		closeModalHandler();
		clearForm(false);
		dispatch(loadingAction(false));
	};

	return (
		<div>
			<Input
				id='name'
				name='name'
				type='text'
				placeholder={'Wpisz nazwę'}
				label={'Nazwa konfiguracji'}
				required={form.name.required}
				value={form.name.value}
				errorMessage={form.name.errorMessage}
				valueChangeHandler={updateFormHandler}
			/>

			<BasketContent />
			<div className={styles.buttons}>
				<Button
					type='button'
					variant={ButtonVariant.OutlineWithBorder}
					onClick={saveHandler}>
					Zapisz
				</Button>
				<Button type='button' onClick={orderHandler}>
					Wygeneruj wycenę
				</Button>
			</div>
		</div>
	);
};

export default BasketSummary;
