import React, {useCallback} from 'react';
import Modal from '@components/Modal/Modal';
import BasketContent from '@pages/ConfiguratorPage/components/BasketSummary/BasketContent';
import Button, {ButtonVariant} from '@components/Button/Button';
import styles from './ConfirmModal.module.scss';
import {useNavigate} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import {deleteConfigurationRequest} from '@api/configuration';
import {removeConfig} from '@actions/savedConfigsPageActions';
import {toast} from 'react-toastify';
import {Text} from '@components/_Typography';
import {loadingAction} from '@actions/appActions';
// import {getBasketId} from '@selectors/basketSelectors';
// import {createOfferRequest} from '@api/offer';
// import {clearBasket} from '@actions/basketActions';

export enum ConfirmModalType {
	CLOSED,
	ORDER,
	MODIFY,
	DELETE,
}

interface ConfirmModalProps {
	closeHandler: () => void;
	type: ConfirmModalType;
	currentId?: number;
}

const ConfirmModal = (props: ConfirmModalProps) => {
	const {closeHandler, type, currentId} = props;

	const navigate = useNavigate();
	const dispatch = useDispatch();

	// const basketId = useSelector(getBasketId);

	const submitHandler = useCallback(async () => {
		switch (type) {
			// case ConfirmModalType.ORDER:
			// 	if (!!!basketId) return;
			// 	dispatch(loadingAction(true));
			// 	await createOfferRequest(basketId);
			// 	dispatch(loadingAction(false));
			// 	dispatch(clearBasket());
			// 	break;
			case ConfirmModalType.MODIFY:
				navigate('/configurator');
				break;
			case ConfirmModalType.DELETE:
				if (!currentId) return toast.error('Nie można usunąć elementu.');
				dispatch(loadingAction(true));
				await deleteConfigurationRequest(`${currentId}`).then(() => {
					dispatch(removeConfig(currentId));
				});
				dispatch(loadingAction(false));
				break;
			default:
				throw new Error('Unknow submit type.');
		}
		closeHandler();
	}, [closeHandler, currentId, dispatch, navigate, type]);

	return (
		<Modal
			title={
				type === ConfirmModalType.DELETE
					? `Czy chcesz usunąć zapisaną konfigurację o id: ${currentId}?`
					: type === ConfirmModalType.ORDER
					? 'Wygeneruj wycenę'
					: 'Modyfikacja konfiguracji'
			}
			closeHandler={closeHandler}>
			{type !== ConfirmModalType.DELETE && <BasketContent />}
			{type === ConfirmModalType.DELETE && (
				<Text>Usunięcie zapisanej konfiguracji jest nieodwracalne.</Text>
			)}
			<div className={styles.buttons}>
				<Button variant={ButtonVariant.OutlineWithBorder} onClick={closeHandler}>
					Anuluj
				</Button>
				<Button onClick={submitHandler}>Potwierdź</Button>
			</div>
		</Modal>
	);
};

export default ConfirmModal;
