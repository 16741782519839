import {AllAttributes, setCurrentTab} from '@actions/configuratorActions';
import {TabModel} from '@api/models/configuration';
import { UnknownAction } from '@reduxjs/toolkit';
import { Dispatch } from 'react';
import {toast} from 'react-toastify';

const openMissedAttributeTab = (
	tabAttributes: TabModel[],
	missedCategoryId: number,
	dispatch: Dispatch<UnknownAction>
) => {
	const tabOfMissedCategory = tabAttributes.find((tab) =>
		tab.categories.find((category) => category.categoryId === missedCategoryId)
	);

	if(!!tabOfMissedCategory){
		dispatch(setCurrentTab(tabOfMissedCategory.tabId))
	}
};

// Check does contain all required attributes
export const standardsValidation = (
	allAttributesObject: AllAttributes | undefined,
	selectedAttributes: number[],
	tabAttributes: TabModel[] | null | undefined,
	dispatch: Dispatch<UnknownAction>
): boolean => {
	const allAttributesArr = allAttributesObject
		? Object.keys(allAttributesObject)
		: [];
	const allRequiredCategories = allAttributesObject
		? allAttributesArr.map((id) =>
				allAttributesObject[+id].isRequired
					? allAttributesObject[+id].categoryId
					: undefined
		  )
		: [];

	const requiredCategories = Array.from(
		new Set(allRequiredCategories.filter((id) => id !== undefined))
	);

	const categoriesFromSelectedAttributes = selectedAttributes.map(
		(id) => allAttributesObject?.[id].categoryId
	);

	const missedCategory = requiredCategories.find(
		(id) => !categoriesFromSelectedAttributes.includes(id)
	);

	if (!!missedCategory) {
		if (tabAttributes) openMissedAttributeTab(tabAttributes, missedCategory, dispatch);
		toast.warning('Nie wybrano wszystkich wymaganych opcji.');
		return false;
	}

	return true;
};
