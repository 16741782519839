import {View, Image, Text, StyleSheet} from '@react-pdf/renderer';
import React from 'react';
import logo from '@assets/images/logo-admin.png';

const Header = () => {
	const styles = StyleSheet.create({
		page: {
			padding: '20px 35px',
			fontFamily: 'Roboto',
			color: '#005179',
		},
		header: {
			borderBottom: '3px solid #00BBEE',
			paddingBottom: 15,
			display: 'flex',
			flexDirection: 'row',
			justifyContent: 'space-between',
			alignItems: 'center',
		},
		headerText: {
			fontSize: 9,
			opacity: 0.75,
		},
		logo: {width: '30%'},
		space: {
			height: 20,
		},
	});

	return (
		<>
			<View style={styles.header} fixed>
				<Image style={styles.logo} src={logo} />
				<View>
					<Text style={styles.headerText}>Daszyna 29a,</Text>
					<Text style={styles.headerText}>99-107 Daszyna</Text>
				</View>
			</View>
			<View style={styles.space} fixed></View>
		</>
	);
};

export default Header;
