import React from 'react';
import {ReactComponent as SuccessSvg} from '@assets/icons/success.svg';
import styles from '../ContactForm.module.scss';

const SuccessStep = (props: {title?: React.ReactNode}) => {
	return (
		<div className={styles.done}>
			<SuccessSvg />
			<p className={styles.title}>
				{props.title ? props.title : 'Wiadomość wysłana'}
			</p>
		</div>
	);
};

export default SuccessStep;
