import {
	setBodyTypes,
	setCabinTypes,
	setChassis,
	setModels,
} from '@actions/configuratorActions';
import {
	getBodyTypes,
	getCabinTypes,
	getChassis,
	getModels,
} from '@api/configuration';
import {loadingAction} from '@actions/appActions';
import {configuratorSelectors} from '@selectors/configuratorSelectors';
import React, {useEffect, useMemo, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {initialState} from '@pages/ConfiguratorPage/Configurator';
import {FormField, FormKeys} from '@commonTypes/main';
import Select from '@components/_FormElements/Select/Select';
import {Tab} from '@reducers/configuratorReducer';
import {Title} from '@components/_Typography';
import {TitleTag, TitleVariant} from '@components/_Typography/Title/Title';
import {getAuth} from '@selectors/authSelectors';
import styles from './SelectVechicle.module.scss';
import configStyles from '../../Configurator.module.scss';
import {getBasket} from '@selectors/basketSelectors';
import {InfoProps} from '@components/_FormElements/Info/Info';

interface SelectVechicleProps {
	form: FormKeys<typeof initialState>;
	updateFormHandler: (name: string, value: FormField) => void;
}

const SelectVechicle = (props: SelectVechicleProps) => {
	const {form, updateFormHandler} = props;

	const [withoutCabin, setWithoutCabin] = useState<boolean>(false);

	const bodyTypes = useSelector(configuratorSelectors.getBodyTypes);
	const models = useSelector(configuratorSelectors.getModels);
	const cabinTypes = useSelector(configuratorSelectors.getCabinTypes);
	const chassis = useSelector(configuratorSelectors.getChassis);
	const currentTab = useSelector(configuratorSelectors.getCurrentTab);
	const auth = useSelector(getAuth);
	const basket = useSelector(getBasket);

	const dispatch = useDispatch();

	// Download body types
	useEffect(() => {
		(async () => {
			if (!!basket) return;
			if (!!!bodyTypes?.length && auth?.brandId) {
				dispatch(loadingAction(true));

				await getBodyTypes(auth.brandId)
					.then((res) => dispatch(setBodyTypes(res.value)))
					.finally(() => {
						dispatch(loadingAction(false));
					});
			}
		})();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch, auth?.brandId, basket]);

	// Download models
	useEffect(() => {
		(async () => {
			if (!!basket) return;
			if (!!form.step1.bodyType.value && auth?.brandId) {
				dispatch(loadingAction(true));

				await getModels(auth.brandId, form.step1.bodyType.value)
					.then((res) => dispatch(setModels(res.value)))
					.finally(() => {
						dispatch(loadingAction(false));
					});
			}
		})();
	}, [form.step1.bodyType.value, dispatch, auth?.brandId, basket]);

	// Download cabins
	useEffect(() => {
		(async () => {
			if (!!basket) return;
			if (!!form.step1.bodyType.value && !!form.step1.model.value) {
				dispatch(loadingAction(true));

				await getCabinTypes(form.step1.bodyType.value, form.step1.model.value)
					.then((res) => {
						dispatch(setCabinTypes(res.value));

						const isNull = !!res.value?.find(({name}) => name === null);
						setWithoutCabin(isNull);

						if (isNull) {
							updateFormHandler('cabinType', {...form.step1.cabinType, value: null});
						}
					})
					.finally(() => {
						dispatch(loadingAction(false));
					});
			}
		})();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [form.step1.bodyType.value, dispatch, form.step1.model.value, basket]);

	useEffect(() => {
		if (!!!cabinTypes) return;

		const isNull = !!cabinTypes.find(({name}) => name === null);
		setWithoutCabin(isNull);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [cabinTypes, updateFormHandler]);

	// Download chassis
	useEffect(() => {
		(async () => {
			if (!!basket) return;
			if (
				!!form.step1.bodyType.value &&
				!!form.step1.model.value &&
				form.step1.cabinType.value !== 0
			) {
				dispatch(loadingAction(true));
				await getChassis(
					form.step1.bodyType.value,
					form.step1.model.value,
					form.step1.cabinType.value
				)
					.then((res) => dispatch(setChassis(res.value)))
					.finally(() => {
						dispatch(loadingAction(false));
					});
			}
		})();
	}, [
		form.step1.bodyType.value,
		form.step1.model.value,
		form.step1.cabinType.value,
		dispatch,
		basket,
	]);

	const currentBody: InfoProps | undefined = useMemo(() => {
		const current = bodyTypes?.find(
			(item) => item.id === form.step1.bodyType.value
		);

		return !!current
			? {title: current.name, description: '', imageUrl: current.image}
			: undefined;
	}, [bodyTypes, form.step1.bodyType.value]);

	const currentCabin: InfoProps | undefined = useMemo(() => {
		const current = cabinTypes?.find(
			(item) => item.id === form.step1.cabinType.value
		);

		return !!current?.name
			? {title: current.name, description: '', imageUrl: current.imageUrl}
			: undefined;
	}, [cabinTypes, form.step1.cabinType.value]);

	return (
		<div data-current-tab={currentTab === Tab.Vehicle}>
			<Title
				tag={TitleTag.h2}
				variant={TitleVariant.section}
				classes={configStyles.title}>
				Wybierz pojazd
			</Title>
			<div className={styles.options}>
				<Select<number | undefined>
					id='bodyType'
					name='bodyType'
					label={'Rodzaj zabudowy'}
					value={form.step1.bodyType.value}
					errorMessage={form.step1.bodyType.errorMessage}
					valueChangeHandler={updateFormHandler}
					defaultOption={{value: 0, label: 'Wybierz rodzaj zabudowy'}}
					options={bodyTypes?.map((bodyType) => ({
						value: bodyType.id,
						label: `${bodyType.name}`,
					}))}
					zIndex={99}
					required
					info={currentBody}
				/>
				{form.step1.bodyType.value !== 0 && (
					<Select<number | undefined>
						id='model'
						name='model'
						label={'Model pojazdu'}
						value={form.step1.model.value}
						errorMessage={form.step1.model.errorMessage}
						valueChangeHandler={updateFormHandler}
						defaultOption={{value: 0, label: 'Wybierz model pojazdu'}}
						options={models?.map((model) => ({
							value: model.modelId,
							label: `${model.name}`,
						}))}
						zIndex={98}
						required
					/>
				)}
				{form.step1.model.value !== 0 &&
					form.step1.cabinType.value !== null &&
					!withoutCabin &&
					!!cabinTypes?.length && (
						<Select<number | undefined>
							id='cabinType'
							name='cabinType'
							label={'Kabina'}
							value={form.step1.cabinType.value}
							errorMessage={form.step1.cabinType.errorMessage}
							valueChangeHandler={updateFormHandler}
							defaultOption={{value: 0, label: 'Wybierz kabinę'}}
							options={cabinTypes.map((cabin) => ({
								value: cabin.id,
								label: `${cabin.name}`,
							}))}
							zIndex={97}
							required
							info={currentCabin}
						/>
					)}
				{form.step1.cabinType.value !== 0 && !!chassis?.length && (
					<Select<number | undefined>
						id='vechicleId'
						name='vechicleId'
						label={'Rodzaj podwozia'}
						value={form.step1.vechicleId.value}
						errorMessage={form.step1.vechicleId.errorMessage}
						valueChangeHandler={updateFormHandler}
						defaultOption={{value: 0, label: 'Wybierz rodzaj podwozia'}}
						options={chassis?.map((chassis) => ({
							value: chassis.id,
							label: `${chassis.chassis}`,
						}))}
						zIndex={96}
						required
					/>
				)}
			</div>
		</div>
	);
};

export default SelectVechicle;
