import {BrowserRouter, Routes, Route, Navigate} from 'react-router-dom';
import {ToastContainer} from 'react-toastify';
// Components
import AppLayout from '@components/Layout/AppLayout';
import routes from './routes';
// Styles
import 'react-range-slider-input/dist/style.css';
import '@styles/main.scss';
import 'react-toastify/dist/ReactToastify.css';
import {useDispatch, useSelector} from 'react-redux';
import {getAuth} from '@selectors/authSelectors';
import {useEffect} from 'react';
import {logInAction} from '@actions/authActions';
import TermsOfUse from '@components/TermsOfUse/TermsOfUse';

function App() {
	const screen = (hasLayout: boolean, element: React.ReactNode) =>
		!!hasLayout ? <AppLayout>{element}</AppLayout> : <>{element}</>;

	return (
		<>
			<BrowserRouter>
				<Routes>
					{routes.map(({isProtected, path, element, id, hasLayout}) => (
						<Route
							key={id}
							path={path}
							element={
								isProtected ? (
									<ProtectedRoute element={screen(hasLayout, element)} />
								) : (
									screen(hasLayout, element)
								)
							}
						/>
					))}
				</Routes>
			</BrowserRouter>
			<div className='toast'>
				<ToastContainer
					position='bottom-right'
					autoClose={2500}
					hideProgressBar={false}
					newestOnTop={false}
					closeOnClick
					rtl={false}
					pauseOnFocusLoss
					pauseOnHover
					theme='light'
				/>
			</div>
		</>
	);
}

const ProtectedRoute = (props: {element: React.ReactNode}): JSX.Element => {
	const dispatch = useDispatch();

	const auth = useSelector(getAuth);

	const isAuthenticated =
		!!localStorage.getItem('jwttoken') && !!localStorage.getItem('userDetails');
	const acepptedTerms =
		sessionStorage.getItem('termsOfUse') === 'true' ||
		localStorage.getItem('termsOfUse') === 'true';

	useEffect(() => {
		if (!!localStorage.getItem('jwttoken') && !!auth) return;
		const userDetails = localStorage.getItem('userDetails');
		if (!!!userDetails || !!auth) return;
		dispatch(logInAction(JSON.parse(userDetails)));
	}, [auth, dispatch]);

	return isAuthenticated ? (
		!acepptedTerms ? (
			<TermsOfUse />
		) : (
			<>{props.element}</>
		)
	) : (
		<Navigate to='/login' replace />
	);
};

export default App;
