// Liobraries
import {FormEvent, useState} from 'react';
// Components
import Container from '@components/Container/Container';
import {Title} from '@components/_Typography';
import Button, {ButtonTag, ButtonVariant} from '@components/Button/Button';
import Logo from '@components/Logo/Logo';
import Input, {defaultInput} from '@components/_FormElements/Input/Input';
// Types
import {Tag} from '@commonTypes/tags';
import {TitleTag, TitleVariant} from '@components/_Typography/Title/Title';
// API
import {login} from '@api/auth';
// Types
import {FormField, FormKeys} from '@commonTypes/main';
// Styles
import styles from './Login.module.scss';
import {validateForm} from '@helpers/validateForm';
import {toast} from 'react-toastify';
import {useNavigate} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import {logInAction} from '@actions/authActions';

const initialState = {
	email: {...defaultInput, required: true},
	userPassword: {...defaultInput, required: true},
};

const Login = () => {
	const [form, setForm] = useState<FormKeys<typeof initialState>>(initialState);

	const navigate = useNavigate();
	const dispatch = useDispatch();

	const updateFormHandler = (name: string, value: FormField) => {
		setForm({...form, [name]: value});
	};

	const submitHandler = (e: FormEvent) => {
		e.preventDefault();
		const isError = validateForm(form, setForm);

		if (isError) return toast.warning('Popraw formularz.');

		login({email: form.email.value, password: form.userPassword.value}).then(
			(res) => {
				localStorage.setItem('jwttoken', res.token);
				localStorage.setItem('userDetails', JSON.stringify(res.userDetails));
				dispatch(logInAction(res.userDetails));
				setTimeout(() => {
					navigate('/');
				}, 500);
			}
		);
	};

	return (
		<Container as={Tag.Main} classes={styles.wrapper}>
			<section className={styles.formWrapper}>
				<Logo />
				<Title
					tag={TitleTag.h1}
					variant={TitleVariant.section}
					classes={styles.title}>
					Zaloguj się
				</Title>
				<form onSubmit={submitHandler}>
					<Input
						id='email'
						name='email'
						type='email'
						placeholder={'Wpisz swój login'}
						label={'Login'}
						required={form.email.required}
						value={form.email.value}
						errorMessage={form.email.errorMessage}
						valueChangeHandler={updateFormHandler}
					/>
					<Input
						id='userPassword'
						name='userPassword'
						type='password'
						label={'Hasło'}
						placeholder={'Wpisz hasło'}
						value={form.userPassword.value}
						required={form.userPassword.required}
						valueChangeHandler={updateFormHandler}
					/>
					<Button
						as={ButtonTag.InternalLink}
						variant={ButtonVariant.Outline}
						classes={styles.passwordBtn}
						href='/pssword-reset'>
						Zapomniałem hasła
					</Button>
					<Button classes={styles.submitBtn} fullWidth type='submit'>
						Zaloguj się
					</Button>
					<Button
						as={ButtonTag.InternalLink}
						variant={ButtonVariant.Outline}
						classes={styles.regiserBtn}
						href='/register'>
						Utwórz konto
					</Button>
				</form>
			</section>
		</Container>
	);
};

export default Login;
