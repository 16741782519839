// Liobraries
import {FormEvent, useCallback, useMemo, useState} from 'react';
// Components
import Container from '@components/Container/Container';
import {Title} from '@components/_Typography';
import Button, {ButtonTag, ButtonVariant} from '@components/Button/Button';
import Logo from '@components/Logo/Logo';
import Input, {defaultInput} from '@components/_FormElements/Input/Input';
// Types
import {Tag} from '@commonTypes/tags';
import {TitleTag, TitleVariant} from '@components/_Typography/Title/Title';
// API
// Types
import {FormField, FormKeys} from '@commonTypes/main';
// Styles
import styles from './PasswordReset.module.scss';
import {toast} from 'react-toastify';
import {validateForm} from '@helpers/validateForm';
import {passwordReset} from '@api/auth';
import {FormSteps} from '@components/ContactForm/ContactForm';
import Loader from '@components/Loader/Loader';
import SuccessStep from '@components/ContactForm/Steps/SuccessStep';
import ErrorStep from '@components/ContactForm/Steps/ErrorStep';

const initialState = {
	email: {...defaultInput, required: true},
};

const PasswordReset = () => {
	const [form, setForm] = useState<FormKeys<typeof initialState>>(initialState);
	const [step, setStep] = useState<FormSteps>(FormSteps.Form);

	const updateFormHandler = (name: string, value: FormField) => {
		setForm({...form, [name]: value});
	};

	const submitHandler = useCallback(
		async (e: FormEvent) => {
			e.preventDefault();
			const isError = validateForm(form, setForm);

			if (isError) return toast.warning('Popraw formularz.');

			setStep(FormSteps.Sending);

			await passwordReset(form.email.value)
				.then(() => {
					setStep(FormSteps.Success);
					setForm(initialState);
				})
				.catch(() => {
					setStep(FormSteps.Error);
				});
		},
		[form]
	);

	const currentStep = useMemo(() => {
		switch (step) {
			case FormSteps.Form:
				return;
			case FormSteps.Sending:
				return <Loader title='Przesyłanie prośby o zreesetowanie hasła.' />;
			case FormSteps.Success:
				return (
					<>
						<SuccessStep
							title={
								<span>
									Prośba o reset hasła została wysłana.
									<br />
									Informacje otrzymasz w przeciągu 24h w dniu roboczym.
								</span>
							}
						/>
						<Button
							as={ButtonTag.InternalLink}
							variant={ButtonVariant.Primary}
							classes={styles.backToLogin}
							href='/login'>
							Powrót do logowania
						</Button>
					</>
				);
			case FormSteps.Error:
				return (
					<>
						<ErrorStep />
						<Button
							variant={ButtonVariant.Primary}
							classes={styles.backToLogin}
							onClick={() => {
								setStep(FormSteps.Form);
							}}>
							Spróbuj jeszcze raz
						</Button>
					</>
				);
			default:
				throw new Error('Unknown ContactForm step.');
		}
	}, [step]);

	return (
		<Container as={Tag.Main} classes={styles.wrapper}>
			<section className={styles.formWrapper}>
				<Logo />
				<Title
					tag={TitleTag.h1}
					variant={TitleVariant.section}
					classes={styles.title}>
					Zresetuj hasło
				</Title>
				<form onSubmit={submitHandler}>
					{step === FormSteps.Form ? (
						<>
							<div className={styles.fieldsWrapper}>
								<Input
									id='email'
									name='email'
									type='email'
									placeholder={'Wpisz e-mail'}
									label={'E-mail'}
									required={form.email.required}
									value={form.email.value}
									errorMessage={form.email.errorMessage}
									valueChangeHandler={updateFormHandler}
								/>
							</div>
							<div className={styles.buttons}>
								<Button classes={styles.submitBtn} type='submit' fullWidth>
									Zresetuj hasło
								</Button>
								<Button
									as={ButtonTag.InternalLink}
									variant={ButtonVariant.OutlineWithBorder}
									classes={styles.submitBtn}
									href='/login'>
									Anuluj
								</Button>
							</div>
						</>
					) : (
						currentStep
					)}
				</form>
			</section>
		</Container>
	);
};

export default PasswordReset;
