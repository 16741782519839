import { GetOfferResponse, OfferModel } from "@api/models/offer";

export interface OrderedConfigsPage {
	configs: OfferModel[] | null;
	currentPage: number;
	maxPage: number;
}

export enum OrderedConfigsPageActions {
	SET_ORDERED_CONFIGS = 'SET_ORDERED_CONFIGS',
	REMOVE_CONFIG = 'REMOVE_CONFIG',
	SET_CURRENT_PAGE = 'SET_CURRENT_PAGE',
	SET_MAX_PAGE = 'SET_MAX_PAGE',
}

const setOrderedConfigs = (configsRes: GetOfferResponse) => ({
	type: OrderedConfigsPageActions.SET_ORDERED_CONFIGS,
	payload: configsRes,
});

const removeConfig = (configId: string) => ({
	type: OrderedConfigsPageActions.REMOVE_CONFIG,
	payload: configId,
});

const setTabsCurrentPage = (currentPage: number) => ({
	type: OrderedConfigsPageActions.SET_CURRENT_PAGE,
	payload: currentPage,
});

const setTabsMaxPage = (maxPage: number) => ({
	type: OrderedConfigsPageActions.SET_MAX_PAGE,
	payload: maxPage,
});

export {setOrderedConfigs, removeConfig, setTabsCurrentPage, setTabsMaxPage};
