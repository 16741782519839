import React, {
	FormEvent,
	useCallback,
	useEffect,
	useMemo,
	useState,
} from 'react';
import SelectVechicle from './components/SelectVechicle/SelectVechicle';
import {defaultInput} from '@components/_FormElements/Input/Input';
import {FormField, FormKeys} from '@commonTypes/main';
import Container from '@components/Container/Container';
import {Tag} from '@commonTypes/tags';
import {getVechicle} from '@api/configuration';
import {useDispatch, useSelector} from 'react-redux';
import {
	clearVechicleAndAttributes,
	setCurrentTab,
	setInitiConfig,
	setTabAttributes,
	setVechicle,
} from '@actions/configuratorActions';
import {loadingAction} from '@actions/appActions';
import Tabs from './components/Tabs/Tabs';
import {configuratorSelectors} from '@selectors/configuratorSelectors';
import Loader from '@components/Loader/Loader';
import styles from './Configurator.module.scss';
import TabScreen from './components/TabScreen/TabScreen';
import VehicleSizes from './components/VehicleSizes/VehicleSizes';
import Modal from '@components/Modal/Modal';
import {Text, Title} from '@components/_Typography';
import Button, {ButtonVariant} from '@components/Button/Button';
import {toast} from 'react-toastify';
import {
	DimensionTypes,
	HeightRangeModel,
	PropertyDimensionRangeModel,
	SizeRangeModel,
} from '@api/models/configuration';
import Summary from './components/Summary/Summary';
import {createBasketRequest, deleteBasketRequest} from '@api/basket';
import {
	checkAttributeDimensions,
	findAllRequirements,
	findConflicts,
	findDeeplyConnectedAttributes,
	findDeeplyExcludedAttributes,
	hiddenAttributesWithoutConnections,
} from '@helpers/attributesHelpers';
import {clearBasket, createBasket} from '@actions/basketActions';
import BasketSummary from './components/BasketSummary/BasketSummary';
import {getAppLoading} from '@selectors/appSelectors';
import {Tab} from '@reducers/configuratorReducer';
import {getBasket} from '@selectors/basketSelectors';
import {
	getCategoriesFromSelectedAttributes,
	spoilerValidation,
} from '@helpers/spoiler';
import {standardsValidation} from '@helpers/standards';
import {TitleTag, TitleVariant} from '@components/_Typography/Title/Title';

export const initialState = {
	step1: {
		bodyType: {...defaultInput, value: 0, required: true},
		model: {...defaultInput, value: 0, required: true},
		cabinType: {...defaultInput, value: 0 as number | null, required: true},
		vechicleId: {...defaultInput, value: 0, required: true},
	},
	stepSizes: {
		width: {...defaultInput, value: 0, required: false},
		length: {...defaultInput, value: 0, required: false},
		height: {...defaultInput, value: 0, required: false},
	},
	step2: null as {[key: string]: FormField} | null,
};

const Configurator = () => {
	const init = useSelector(configuratorSelectors.getInitConfig);
	const [form, setForm] = useState<FormKeys<typeof initialState>>({
		...initialState,
		step1: {
			...initialState.step1,
			vechicleId: {
				...initialState.step1.vechicleId,
				value: init?.vechicleId ?? 0,
			},
			model: {
				...initialState.step1.vechicleId,
				value: init?.modelId ?? 0,
			},
			bodyType: {
				...initialState.step1.vechicleId,
				value: init?.bodyTypeId ?? 0,
			},
			cabinType: {
				...initialState.step1.vechicleId,
				value: init?.cabinId === null ? null : init?.cabinId ?? 0,
			},
		},
	});
	const [sizeModal, setSizeModal] = useState<
		false | PropertyDimensionRangeModel
	>(false);
	const [forceModal, setForceModal] = useState<
		| {
				connected: number[];
				excluded: number[];
				attrId: string;
				value: FormField;
				size?: {name: string; value: FormField};
		  }
		| undefined
	>();
	const [requiredPropModal, setRequiredPropModal] = useState<boolean>(false);
	const [submitModal, setSubmitModal] = useState<boolean>(false);

	const currentVehicle = useSelector(configuratorSelectors.getVechicle);
	const loadingData = useSelector(getAppLoading);
	const tabAttributes = useSelector(configuratorSelectors.getTabAttributes);
	const dependencies = useSelector(
		configuratorSelectors.getAttributesDependencies
	);
	const heights = useSelector(configuratorSelectors.getVechicleHeights);
	const basket = useSelector(getBasket);

	const allAttributesObject = useMemo(
		() => dependencies?.allAttributes,
		[dependencies]
	);

	const dispatch = useDispatch();

	const setRestSteps = useCallback(
		(value: {
			step2: {[key: string]: FormField};
			stepSizes: FormKeys<typeof initialState.stepSizes>;
		}) => {
			setForm({...form, step2: value.step2, stepSizes: value.stepSizes});
		},
		[form]
	);

	useEffect(() => {
		dispatch(setCurrentTab(Tab.Vehicle));
	}, [dispatch]);

	useEffect(() => {
		(async () => {
			if (form.step1.vechicleId.value !== 0) {
				dispatch(loadingAction(true));
				await getVechicle(form.step1.vechicleId.value)
					.then((res) => {
						dispatch(setVechicle(res.value.vechicle));
						dispatch(setTabAttributes(res.value.tabAttributes));

						const formStep2: {
							[key: string]: FormField;
						} = {};

						res.value.tabAttributes.map((tab) =>
							tab.categories.forEach((category) =>
								category.attributes.forEach((attribute) => {
									formStep2[`${attribute.attributeId}`] = {
										...defaultInput,
										value: !!init?.attributes?.length
											? init.attributes.find(({id}) => id === +attribute.attributeId)
											: false,
										required: false,
									};
								})
							)
						);

						const getFormSizeObj = (range: SizeRangeModel[] | HeightRangeModel[]) => {
							const required = range[0].value !== 0;
							const value = required ? range[0].value : 0;

							return {...defaultInput, required, value};
						};

						const widths = res.value.vechicle.vechicleAvailableWidths;
						const heights = res.value.vechicle.vechicleAvailableHeights;
						const lengths = res.value.vechicle.vechicleAvailableLenghts;

						setRestSteps({
							step2: formStep2,
							stepSizes: {
								width: init
									? {...initialState.stepSizes.width, value: init.width}
									: getFormSizeObj(widths),
								height: init
									? {...initialState.stepSizes.height, value: init.heigth}
									: getFormSizeObj(heights),
								length: init
									? {...initialState.stepSizes.length, value: init.length}
									: getFormSizeObj(lengths),
							},
						});
					})
					.finally(() => {
						dispatch(loadingAction(false));
						if (!!init) {
							dispatch(setInitiConfig(null));
						}
					});
			} else {
				dispatch(clearVechicleAndAttributes());
			}
		})();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch, form.step1.vechicleId.value]);

	const updateFormStep1Handler = useCallback(
		(name: string, value: FormField) => {
			switch (name) {
				case 'bodyType':
					if (value.value === form.step1.bodyType.value) return;
					return setForm({
						...form,
						step1: {...initialState.step1, [name]: value},
						step2: null,
						stepSizes: initialState.stepSizes,
					});
				case 'model':
					if (value.value === form.step1.model.value) return;
					return setForm({
						...form,
						step1: {
							...form.step1,
							[name]: value,
							cabinType: initialState.step1.cabinType,
							vechicleId: initialState.step1.vechicleId,
						},
						step2: null,
						stepSizes: initialState.stepSizes,
					});
				case 'cabinType':
					if (value.value === form.step1.cabinType.value) return;
					return setForm({
						...form,
						step1: {
							...form.step1,
							[name]: value,
							vechicleId: initialState.step1.vechicleId,
						},
						step2: null,
						stepSizes: initialState.stepSizes,
					});
				default:
					return setForm({
						...form,
						step1: {...form.step1, [name]: value},
						step2: null,
						stepSizes: initialState.stepSizes,
					});
			}
		},
		[form]
	);

	const updateFormStepSizesHandler = useCallback(
		(name: string, value: FormField) => {
			if (!!!form.step2) throw new Error('Required form element not found!');
			if (!!!allAttributesObject)
				throw new Error('Required form element not found!');

			const formAttributesObj = form.step2;
			const allFormAttributesIds = Object.keys(allAttributesObject);
			const currentValueDimensionId =
				name === 'width'
					? DimensionTypes.Width
					: name === 'height'
					? DimensionTypes.Height
					: name === 'length'
					? DimensionTypes.Length
					: '';

			// Only this attributes with dimension of currently changed value
			const checkedAttributesWithDimensions = allFormAttributesIds
				.filter((id) => {
					const dimention = allAttributesObject[+id].attributeDimension;
					if (dimention === null) {
						return false;
					} else {
						return (
							formAttributesObj[id].value &&
							dimention.dimensionTypeId === currentValueDimensionId &&
							!dimention.isAttributeRequired &&
							!(dimention.maxValue >= value.value && dimention.minValue <= value.value)
						);
					}
				})
				.map((id) => +id);

			// Uncheck attribs with connections if size changed over dimension
			if (!!checkedAttributesWithDimensions.length)
				return setForceModal({
					connected: [],
					excluded: [],
					attrId: `${checkedAttributesWithDimensions[0]}`,
					value: {...form.step2[checkedAttributesWithDimensions[0]], value: false},
					size: {name, value},
				});

			const requiredAttributesForCurrentSize = allFormAttributesIds
				.filter((id) => {
					const dimention = allAttributesObject[+id].attributeDimension;
					if (dimention === null) {
						return false;
					} else {
						return (
							!formAttributesObj[id].value &&
							dimention.dimensionTypeId === currentValueDimensionId &&
							dimention.isAttributeRequired &&
							dimention.maxValue >= value.value &&
							dimention.minValue <= value.value
						);
					}
				})
				.map((id) => +id);

			const forbiddenAttributesForCurrentSize = allFormAttributesIds
				.filter((id) => {
					const dimention = allAttributesObject[+id].attributeDimension;
					if (dimention === null) {
						return false;
					} else {
						return (
							formAttributesObj[id].value &&
							dimention.dimensionTypeId === currentValueDimensionId &&
							!(dimention.maxValue >= value.value && dimention.minValue <= value.value)
						);
					}
				})
				.map((id) => +id);

			if (
				!!requiredAttributesForCurrentSize.length ||
				!!forbiddenAttributesForCurrentSize.length
			) {
				const currentId =
					requiredAttributesForCurrentSize[0] ??
					forbiddenAttributesForCurrentSize[0];

				setForceModal({
					connected: requiredAttributesForCurrentSize,
					excluded: forbiddenAttributesForCurrentSize,
					attrId: `${currentId}`,
					value: {
						...form.step2[currentId],
						value: !form.step2[currentId].value,
					},
					size: {name, value},
				});
			}

			return setForm({
				...form,
				stepSizes: {...form.stepSizes, [name]: value},
			});
		},
		[allAttributesObject, form]
	);

	// Logic for changing attributes
	// Be aware doing changes!
	const updateFormStep2Handler = useCallback(
		(
			currentId: string,
			value: FormField,
			forceChanges?: boolean,
			forceSize?: boolean
		) => {
			if (!!!form.step2) throw new Error('Required form element not found!');
			if (!!!allAttributesObject)
				throw new Error('Required form element not found!');

			const newCheckStatus = value.value;
			const formAttributesObj = form.step2;
			const allFormAttributesIds = Object.keys(allAttributesObject);

			const checkedAttributes = allFormAttributesIds
				.filter((id) => formAttributesObj[id].value)
				.map((id) => +id);

			if (newCheckStatus) {
				// When checking value
				//
				// Requred attributes logic
				//
				const deeplyConnectedAttributes = findDeeplyConnectedAttributes(
					+currentId,
					allAttributesObject
				);
				// All attributes from this array should be checked this array is reduced of currently checked attributes
				const reducedRequredAttributes = deeplyConnectedAttributes.filter(
					(id) => !checkedAttributes.includes(id)
				);

				const featureCheckedIds = [
					+currentId,
					...reducedRequredAttributes,
					...checkedAttributes,
				];

				//
				// Forbidden attributes logic
				//
				const deeplyForbiddenAttributes = findDeeplyExcludedAttributes(
					checkedAttributes,
					allAttributesObject,
					+currentId
				).filter((item) => !deeplyConnectedAttributes.includes(item)); // Fix connection error when one of element to exclude have the same connections as checked value
				const forbiddenInChecked = checkedAttributes
					.filter(
						(id) =>
							!!findDeeplyExcludedAttributes(
								checkedAttributes,
								allAttributesObject,
								id
							).length
					)
					.map((id) => +id);
				// Deep Exclusions for new checked value
				const forbiddenAttributesForNewValue = Array.from(
					new Set([...forbiddenInChecked, ...deeplyForbiddenAttributes])
				);
				// If some value required to remove is reqired in other currently checked value
				const requiredInCurrentlyChecked = checkedAttributes.filter(
					(id) =>
						!!findDeeplyConnectedAttributes(+id, allAttributesObject).filter(
							(attrId) => forbiddenAttributesForNewValue.includes(attrId)
						).length
				);
				// New required values excludes currently checked values
				const exclusionsForFeatureCheckedIds = checkedAttributes.filter(
					(id) =>
						!!findDeeplyExcludedAttributes(featureCheckedIds, allAttributesObject, id)
							.length
				);

				const allForbidden = Array.from(
					new Set([
						...forbiddenAttributesForNewValue,
						...requiredInCurrentlyChecked,
						...exclusionsForFeatureCheckedIds,
					])
				);

				const reducedForbiddenAttributes = allForbidden.filter((id) =>
					checkedAttributes.includes(id)
				);

				// Check conflicts of connections end exclusions
				const conflicts = findConflicts(
					deeplyConnectedAttributes,
					deeplyForbiddenAttributes,
					+currentId
				);

				// Error! Occures when inside dependecies exist conflict
				if (!!conflicts.length) {
					return toast.error(
						`Cecha zawiera konflikt zależności, dla cech o id: ${conflicts?.toString()}. Przekaż tę informację administratorowi systemu.`
					);
				}
				// Error! Occures when inside dependecies exist connections with attribute wich is not connected with current vehicle
				if (
					deeplyConnectedAttributes.find(
						(id) => allAttributesObject[+id] === undefined
					)
				)
					return toast.error(
						'Konfiguracja zależności zawiera cechy, które nie są dodane dla tego pojazdu.'
					);

				if (
					!!!reducedRequredAttributes.length &&
					!!!reducedForbiddenAttributes.length
				) {
					// Straight dimensions
					const currentDimension =
						allAttributesObject[+currentId].attributeDimension;

					const currentAttributedimensionValid =
						checkAttributeDimensions(
							+form.stepSizes.width.value,
							+form.stepSizes.height.value,
							+form.stepSizes.length.value,
							currentDimension
						) &&
						(!currentDimension?.isAttributeRequired ||
							(currentDimension?.isAttributeRequired &&
								!reducedForbiddenAttributes.includes(+currentId)));
					if (
						!currentAttributedimensionValid &&
						currentDimension !== null &&
						!forceChanges
					)
						return setSizeModal(currentDimension);

					// If there is no connections or eclusions
					return setForm({
						...form,
						step2: {...form.step2, [currentId]: value},
					});
				} else if (forceChanges) {
					// If there is connections or eclusions and changes are forced
					const newStep2 = form.step2;
					reducedRequredAttributes.map(
						(id) => (newStep2[id] = {...newStep2[id], value: true})
					);
					reducedForbiddenAttributes.map(
						(id) => (newStep2[id] = {...newStep2[id], value: false})
					);
					newStep2[currentId] = {...newStep2[currentId], value: newCheckStatus};

					return setForm({
						...form,
						step2: {...form.step2, ...newStep2},
					});
				} else {
					// Deep dimensions
					let isDimension = false;
					[
						...reducedRequredAttributes,
						...reducedForbiddenAttributes,
						currentId,
					].some((id) => {
						const currentDimension = allAttributesObject[+id].attributeDimension;

						const currentAttributedimensionValid =
							checkAttributeDimensions(
								+form.stepSizes.width.value,
								+form.stepSizes.height.value,
								+form.stepSizes.length.value,
								currentDimension
							) &&
							((currentDimension?.isAttributeRequired &&
								!reducedForbiddenAttributes.includes(+id)) ||
								!currentDimension?.isAttributeRequired);

						if (
							!currentAttributedimensionValid &&
							currentDimension !== null &&
							!forceChanges
						) {
							isDimension = true;
							setSizeModal(currentDimension);
							return true;
						}
						return false;
					});

					if (isDimension) return;
					// If there is connections or eclusions and changes are not forced
					return setForceModal({
						connected: reducedRequredAttributes,
						excluded: reducedForbiddenAttributes,
						attrId: currentId,
						value,
					});
				}
			} else {
				// When unchecking value
				const allRequirements = findAllRequirements(
					+currentId,
					allAttributesObject,
					checkedAttributes
				);

				const currentDimension = allAttributesObject[+currentId].attributeDimension;

				const currentAttributedimensionValid =
					checkAttributeDimensions(
						+form.stepSizes.width.value,
						+form.stepSizes.height.value,
						+form.stepSizes.length.value,
						currentDimension
					) && !currentDimension?.isAttributeRequired;

				if (
					!currentAttributedimensionValid &&
					currentDimension !== null &&
					!forceChanges
				)
					return setSizeModal(currentDimension);

				if (!!!allRequirements.length) {
					const hiddenAttrToRemove = hiddenAttributesWithoutConnections(
						+currentId,
						allAttributesObject,
						checkedAttributes
					);

					if (!!hiddenAttrToRemove.length) {
						const newStep2 = form.step2;

						hiddenAttrToRemove.map(
							(id) => (newStep2[id] = {...newStep2[id], value: false})
						);

						return setForm({
							...form,
							step2: {...form.step2, ...newStep2, [currentId]: value},
						});
					}
					// Change value if there is no connections
					return setForm({
						...form,
						step2: {...form.step2, [currentId]: value},
					});
				} else {
					if (!!!forceChanges) {
						// If there is any attribute wich require currently unchecked value
						return setForceModal({
							connected: [],
							excluded: allRequirements,
							attrId: currentId,
							value,
						});
					} else {
						// If there is any attribute wich require currently unchecked value and the changes are forced
						const newStep2 = form.step2;
						allRequirements.map(
							(id) => (newStep2[id] = {...newStep2[id], value: false})
						);
						newStep2[currentId] = {...newStep2[currentId], value: newCheckStatus};

						return setForm({
							...form,
							step2: {...form.step2, ...newStep2},
						});
					}
				}
			}
		},
		[allAttributesObject, form]
	);

	const submitHandler = useCallback(
		async (e: FormEvent) => {
			e.preventDefault();
			if (
				!!form.stepSizes.height.errorMessage ||
				!!form.stepSizes.width.errorMessage ||
				!!form.stepSizes.length.errorMessage
			) {
				dispatch(setCurrentTab(Tab.Sizes));
				return;
			}

			const vechicleId = form.step1.vechicleId.value;
			const height = form.stepSizes.height.value;
			const width = form.stepSizes.width.value;
			const length = form.stepSizes.length.value;
			const attributeIds =
				form.step2 !== null
					? Object.keys(form.step2)
							.filter((id) => form.step2?.[id]?.value)
							.map((id) => +id)
					: [];

			const containRequiredCategoreis = standardsValidation(
				allAttributesObject,
				attributeIds,
				tabAttributes,
				dispatch
			);

			// Check does contain all required attributes
			if (!containRequiredCategoreis) {
				setRequiredPropModal(true);
				return;
			}

			const categoriesFromSelectedAttributes = getCategoriesFromSelectedAttributes(
				allAttributesObject,
				attributeIds
			);

			// Checks is spoiler option valid
			const isSpoilerValid = spoilerValidation(
				heights,
				height,
				categoriesFromSelectedAttributes
			);

			if (!isSpoilerValid) return;

			dispatch(loadingAction(true));

			await createBasketRequest({
				vechicleId,
				height,
				width: width,
				lenght: length,
				attributeIds,
			})
				.then((res) => {
					setSubmitModal(true);
					dispatch(createBasket(res.value));
				})
				.finally(() => {
					dispatch(loadingAction(false));
				});
		},
		[
			allAttributesObject,
			dispatch,
			form.step1.vechicleId.value,
			form.step2,
			form.stepSizes.height.errorMessage,
			form.stepSizes.height.value,
			form.stepSizes.length.errorMessage,
			form.stepSizes.length.value,
			form.stepSizes.width.errorMessage,
			form.stepSizes.width.value,
			heights,
			tabAttributes,
		]
	);

	const clearForm = useCallback(
		async (deleteBasket?: boolean) => {
			setForm(initialState);
			if (basket?.id && !!deleteBasket) {
				dispatch(loadingAction(true));
				await deleteBasketRequest(basket?.id).finally(() => {
					dispatch(loadingAction(false));
				});
			}
			dispatch(clearBasket());
		},
		[basket?.id, dispatch]
	);

	return (
		<>
			<Container as={Tag.Section} classes={styles.wrapper}>
				<Tabs />
				<Title tag={TitleTag.h2} variant={TitleVariant.section}>
					{currentVehicle?.bodyType} {currentVehicle?.brand} {currentVehicle?.model}{' '}
					{currentVehicle?.cabinType ? `${currentVehicle.cabinType} ` : ''}
					{currentVehicle?.chassis}
				</Title>
				<form onSubmit={submitHandler} className={styles.configurator}>
					<SelectVechicle form={form} updateFormHandler={updateFormStep1Handler} />
					<VehicleSizes form={form} updateFormHandler={updateFormStepSizesHandler} />
					{tabAttributes?.map((tab) => (
						<TabScreen
							key={tab.tabId}
							form={form}
							updateFormHandler={updateFormStep2Handler}
							{...tab}
						/>
					))}
					<Summary
						width={form.stepSizes.width.value}
						height={form.stepSizes.height.value}
						length={form.stepSizes.length.value}
						submitHandler={submitHandler}
					/>
				</form>
				{loadingData && (
					<div className={styles.loader}>
						<Loader />
					</div>
				)}
			</Container>
			{requiredPropModal && (
				<Modal
					title={<>Wymagana cecha nie została przez Ciebie wybrana.</>}
					closeHandler={() => setRequiredPropModal(false)}>
					<Text classes={styles.modalText}>
						Konfigurator przeniesie Cię do widoku w którym znajduje się wymagana
						cecha. Po dokonaniu wyboru spróbj ponownie podsumować konfigurację.
					</Text>
					<div className={styles.buttons}>
						<Button
							type='button'
							onClick={() => {
								setRequiredPropModal(false);
							}}>
							Zatwierdź
						</Button>
					</div>
				</Modal>
			)}
			{!!forceModal && (
				<Modal
					title={
						!!forceModal.size ? (
							'Uwaga! Wykryto cechy zależne od wymiarów.'
						) : (
							<>
								Cecha{' '}
								<span>{dependencies?.allAttributes[+forceModal.attrId]?.name}</span>{' '}
								jest zależna od 'innych cech'!
							</>
						)
					}
					hiddenClose={!!forceModal.size}
					closeHandler={() => setForceModal(undefined)}>
					<Text classes={styles.modalText}>
						Czy chcesz wprowadzić zmiany w konfiguracji?
					</Text>
					{!!forceModal.connected.length && (
						<Text>
							<strong>Wymagane dodanie cech: </strong>{' '}
							{forceModal.connected.map(
								(id, index) =>
									`${dependencies?.allAttributes[+id]?.name}${
										index !== forceModal.connected.length - 1 ? ', ' : '.'
									}`
							)}
						</Text>
					)}
					{!!forceModal.excluded.length && (
						<Text>
							<strong>Wymagane usunięcia cech: </strong>{' '}
							{forceModal.excluded.map(
								(id, index) =>
									`${dependencies?.allAttributes[+id].name}${
										index !== forceModal.excluded.length - 1 ? ', ' : '.'
									}`
							)}
						</Text>
					)}

					<div className={styles.buttons}>
						{!!!forceModal.size && (
							<Button
								type='button'
								onClick={() => setForceModal(undefined)}
								variant={ButtonVariant.OutlineWithBorder}>
								Anuluj
							</Button>
						)}
						<Button
							type='button'
							onClick={() => {
								updateFormStep2Handler(forceModal.attrId, forceModal.value, true);
								setForceModal(undefined);
							}}>
							Zatwierdź
						</Button>
					</div>
				</Modal>
			)}
			{sizeModal && (
				<Modal
					title={'Cecha dostępna tylko dla wybranych wymiarów'}
					closeHandler={() => {
						setSizeModal(false);
					}}>
					<Text>
						Cecha dostępna jest tylko dla{' '}
						{sizeModal.dimensionTypeId === DimensionTypes.Height
							? 'wysokości'
							: sizeModal.dimensionTypeId === DimensionTypes.Length
							? 'długości'
							: sizeModal.dimensionTypeId === DimensionTypes.Width
							? 'szerokości'
							: ''}{' '}
						w zakresie {sizeModal.minValue} - {sizeModal.maxValue} mm.
					</Text>
					<div className={styles.buttons}>
						<Button
							type='button'
							variant={ButtonVariant.OutlineWithBorder}
							onClick={() => {
								setSizeModal(false);
							}}>
							Anuluj
						</Button>
						<Button
							type='button'
							onClick={() => {
								dispatch(setCurrentTab(Tab.Sizes));
								setSizeModal(false);
							}}>
							Przejdź do wymiarów
						</Button>
					</div>
				</Modal>
			)}
			{submitModal && (
				<Modal
					title={'Podsumowanie konfiguracji'}
					closeHandler={() => setSubmitModal(false)}>
					<BasketSummary
						clearForm={clearForm}
						closeModalHandler={() => setSubmitModal(false)}
					/>
				</Modal>
			)}
		</>
	);
};

export default Configurator;
