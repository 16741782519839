// Libraries
import {useSelector} from 'react-redux';
// Container
import Container from '@components/Container/Container';
// Types
import {Tag} from '@commonTypes/tags';
// Assets
import userProfile from '@assets/images/default-profile.png'
// Styles
import styles from './Topbar.module.scss';
import { getUsername } from '@selectors/authSelectors';

const Topbar = () => {
	const username = useSelector(getUsername);

	return (
		<Container as={Tag.Aside} classes={styles.wrapper}>
			<div className={styles.profile}>
				<p className={styles.profileText}><span>Witaj, <strong>{username}</strong></span></p>
				<div className={styles.profileImage}>
					<img src={userProfile} alt={username} />
				</div>
			</div>
		</Container>
	);
};

export default Topbar;
