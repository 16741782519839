import React, {useMemo} from 'react';
import {Title, Text} from '@components/_Typography';
import {TitleTag, TitleVariant} from '@components/_Typography/Title/Title';
import styles from './BasketSummary.module.scss';
import {useSelector} from 'react-redux';
import {getBasket} from '@selectors/basketSelectors';

const BasketContent = () => {
	const basket = useSelector(getBasket);

	const sizesCondition = useMemo(
		() => !!basket?.height || !!basket?.lenght || !!basket?.width,
		[basket?.height, basket?.lenght, basket?.width]
	);

	return (
		<>
			<Title
				classes={styles.title}
				tag={TitleTag.h4}
				variant={TitleVariant.standard}>
				{basket?.vechicleName}
			</Title>
			<Text>
				<em>{basket?.description}</em>
			</Text>
			<div className={styles.base}>
				<Text>
					<span>Cena bazowa: {basket?.basePrice} zł</span>
				</Text>
				<Text>
					<span>Waga bazowa: {basket?.baseWeight} kg</span>
				</Text>
			</div>

			{sizesCondition && (
				<>
					<Title
						classes={styles.title}
						tag={TitleTag.h4}
						variant={TitleVariant.standard}>
						Wymiary wewnętrzne:
					</Title>

					<div className={styles.base}>
						<Text>
							<span>Wysokość: {basket?.height} mm</span>
						</Text>
						<Text>
							<span>Szerokość: {basket?.width} mm</span>
						</Text>
						<Text>
							<span>Długość: {basket?.lenght} mm</span>
						</Text>
					</div>
				</>
			)}

			{!!basket?.attributes?.length && (
				<>
					<Title
						classes={styles.title}
						tag={TitleTag.h4}
						variant={TitleVariant.standard}>
						Dodana specyfikacja:
					</Title>
					<ul className={styles.list}>
						{basket?.attributes.map((attr) => (
							<li key={attr.id}>
								<strong>{attr?.name}</strong>{' '}
								<span>
									{attr?.price} {attr.isEuroPrice ? '€' : 'zł'} / {attr?.weight} kg
								</span>
							</li>
						))}
					</ul>
				</>
			)}
			<hr className={styles.separator} />
			<Text>
				<strong>Koszt całkowity:</strong> {basket?.totalPrice} zł
			</Text>
			<Text>
				<strong className={styles.hidden}>Koszt całkowity:</strong>{' '}
				{!!basket?.totalPriceEuro && `+ ${basket?.totalPriceEuro} €`}
			</Text>
			<Text>
				<strong>Waga całkowita:</strong> {basket?.totalWeight} kg
			</Text>
		</>
	);
};

export default BasketContent;
