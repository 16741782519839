import { useSelector } from 'react-redux';
// Components
import AppHeader from '@components/AppHeader/AppHeader';
import Container from '@components/Container/Container';
import ChangePassword from './components/ChangePassword/ChangePassword';
import ChangeData from './components/ChangeData/ChangeData';
// Redux
import { getAuth } from '@selectors/authSelectors';
// Types
import {Tag} from '@commonTypes/tags';
// Styles
import styles from './Settings.module.scss';


const Settings = () => {
	const userData = useSelector(getAuth);

	return (
		<>
			<AppHeader title='Ustawienia użytkownika' text='Zarządzaj swoimi danymi.' />
			<Container as={Tag.Section} classes={styles.wrapper}>
				{!!userData && <ChangeData userData={userData}/>}
				<ChangePassword />
			</Container>
		</>
	);
};

export default Settings;
