import {TabModel} from '@api/models/configuration';
import {configuratorSelectors} from '@selectors/configuratorSelectors';
import React from 'react';
import {useSelector} from 'react-redux';
import Category from './components/Category/Category';
import { Title } from '@components/_Typography';
import { TitleTag, TitleVariant } from '@components/_Typography/Title/Title';
import styles from '../../Configurator.module.scss'

interface TabScreenProps extends TabModel {
	form: any;
	updateFormHandler: any;
}

//Wczytywanie opcji w zależności od currentTab
const TabScreen = (props: TabScreenProps) => {
	const {form, categories, updateFormHandler, tabId, tabName} = props;

	const currentTab = useSelector(configuratorSelectors.getCurrentTab);

	return (
		<div data-current-tab={currentTab === tabId}>
			<Title tag={TitleTag.h2} variant={TitleVariant.section} classes={styles.title}>
				Zakładka: {tabName}
			</Title>
			{categories.map((category) => (
				<Category
					key={category.categoryId}
					{...category}
					form={form}
					updateFormHandler={updateFormHandler}
				/>
			))}
		</div>
	);
};

export default TabScreen;
