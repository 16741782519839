import {BasketModel} from '@api/models/basket';

export enum BasketActions {
	CREATE_BASKET = 'CREATE_BASKET',
	CLEAR_BASKET = 'CLEAR_BASKET',
}

export interface BasketState {
	basket: BasketModel | null;
}

const createBasket = (basket: BasketModel) => ({
	type: BasketActions.CREATE_BASKET,
	payload: basket,
});

const clearBasket = () => ({type: BasketActions.CLEAR_BASKET});

export {createBasket, clearBasket};
