// Components
import {Title} from '@typography/index';
// Assets
import {ReactComponent as CloseSvg} from '@assets/icons/close.svg';
// Styles
import styles from './Modal.module.scss';
// Types
import {Common} from '@commonTypes/main';
import {TitleTag, TitleVariant} from '@typography/Title/Title';
import ReactDOM from 'react-dom';

interface ModalProps extends Common {
	title: React.ReactNode;
	closeHandler: () => void;
	hiddenClose?: boolean;
}

const Modal = (props: ModalProps) => {
	const {title, children, closeHandler, hiddenClose} = props;

	const modalContent = (
		<div className={styles.wrapper}>
			<div className={styles.content}>
				<div className={styles.header}>
					<Title
						tag={TitleTag.h3}
						variant={TitleVariant.standard}
						classes={styles.title}>
						{title}
					</Title>
					{!!!hiddenClose && (
						<button type='button' className={styles.close} onClick={closeHandler}>
							<CloseSvg />
						</button>
					)}
				</div>
				{children}
			</div>
		</div>
	);

	return ReactDOM.createPortal(modalContent, document.querySelector('body')!);
};

export default Modal;
