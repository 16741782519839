// Actions
import {AuthActions} from '@actions/authActions';
// Types
import {UserDataModel, UserDetailsModel} from '@api/models/authModels';

const initialState: UserDetailsModel | null = null;

export const authReducer = (
	state = initialState,
	action: {
		type: AuthActions;
		payload: UserDetailsModel | UserDataModel | boolean;
	}
): UserDetailsModel | null => {
	switch (action.type) {
		case AuthActions.LOG_IN:
			return action?.payload as UserDetailsModel;
		case AuthActions.UPDATE_USER_DATA:
			if (state === null) return state;
			return {
				...(state as UserDetailsModel),
				...(action.payload as UserDataModel),
			};
		case AuthActions.LOG_OUT:
			localStorage.removeItem('jwttoken');
			localStorage.removeItem('userDetails');

			return initialState;
		default:
			return state;
	}
};
