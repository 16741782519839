import React, {useMemo} from 'react';
import styles from './PasswordCheck.module.scss';

export const DIGIT_REGEX = /\d/;
export const SPECIAL_CHAR_REGEX = /[!@#$%^&*(),.?":{}|<>]/;
export const UPPER_REGEX = /[A-Z]/;

interface PasswordCheckProps {
	password: string;
}

const PasswordCheck = (props: PasswordCheckProps) => {
	const {password} = props;


	const validCheck = useMemo(
		() => [
			{
				id: '01',
				isValid: password.trim().length >= 8,
				text: 'min. 8 znaków',
			},
			{
				id: '02',
				isValid: DIGIT_REGEX.test(password),
				text: 'min. 1 liczbę',
			},
			{
				id: '03',
				isValid: SPECIAL_CHAR_REGEX.test(password),
				text: 'min. 1 znak specjalny',
			},
			{
				id: '04',
				isValid: UPPER_REGEX.test(password),
				text: 'min. 1 wielką literę',
			},
		],
		[password]
	);

	return (
		<>
			<h3 className={styles.title}>Hasło musi zawierać:</h3>
			<ul className={styles.wrapper}>
				{validCheck.map(({id, isValid, text}) => (
					<li key={id} className={styles.item} data-is-valid={isValid}>
						{text}
					</li>
				))}
			</ul>
		</>
	);
};

export default PasswordCheck;
