// Pages
import Login from '@pages/LoginPage/Login';
import Page404 from '@pages/404Page/Page404';
import Help from '@pages/HelpPage/Help';
import Configurator from '@pages/ConfiguratorPage/Configurator';
import Register from '@pages/RegisterPage/Register';
import PasswordReset from '@pages/PasswordResetPage/PasswordReset';
import PasswordSetup from '@pages/PasswordSetupPage/PasswordSetup';
import SavedConfigs from '@pages/SavedConfigsPage/SavedConfigs';
import Home from '@pages/HomePage/Home';
import OrderedConfigs from '@pages/OrderedConfigsPage/OrderedConfigs';
import Settings from '@pages/SettingsPage/Settings'
// Types
import {Route} from '@commonTypes/main';
import PreviewOffer from '@pages/PreviewOfferPage/PreviewOffer';

const routes: Route[] = [
	{
		id: 'homeRoute',
		path: '/',
		element: <Home />,
		hasLayout: true,
		isProtected: true,
	},
	{
		id: 'loginRoute',
		path: '/login',
		element: <Login />,
		hasLayout: false,
	},
	{
		id: 'registerRoute',
		path: '/register',
		element: <Register />,
		hasLayout: false,
	},
	{
		id: 'passwordResetRoute',
		path: '/pssword-reset',
		element: <PasswordReset />,
		hasLayout: false,
	},
	{
		id: 'passwordResetRoute',
		path: '/setup-new-password',
		element: <PasswordSetup />,
		hasLayout: false,
	},
	{
		id: 'helpRoute',
		path: '/help',
		element: <Help />,
		hasLayout: true,
		isProtected: true,
	},
	{
		id: 'configuratorRoute',
		path: '/configurator',
		element: <Configurator />,
		hasLayout: true,
		isProtected: true,
	},
	{
		id: 'savedConfigsRoute',
		path: '/saved-configs',
		element: <SavedConfigs />,
		hasLayout: true,
		isProtected: true,
	},
	{
		id: 'orderedConfigsRoute',
		path: '/ordered-configs',
		element: <OrderedConfigs />,
		hasLayout: true,
		isProtected: true,
	},
	{
		id: 'settingsRoute',
		path: '/settings',
		element: <Settings />,
		hasLayout: true,
		isProtected: true,
	},
	{
		id: 'pdfRoute',
		path: '/ordered-configs/:id',
		element: <PreviewOffer />,
		hasLayout: true,
		isProtected: true,
	},
	{
		id: '404Route',
		path: '/*',
		element: <Page404 />,
		hasLayout: false,
	},
];

export default routes;
