// Helpers
import { requestCreator } from '@helpers/requsetCreator';
// Types
import { ChangePasswordData, LoginData, LoginResponse, RegisterData, ResetPasswordData } from './models/authModels';

export const login = async (
	data: LoginData
): Promise<LoginResponse> => await requestCreator<LoginData, LoginResponse>('POST', '/auth/login', data)

export const register = async (
	data: RegisterData
): Promise<string> => await requestCreator<RegisterData, string>('POST', '/auth/register', data)

export const passwordReset = async (
	login: string
): Promise<string> => await requestCreator<RegisterData, string>('POST', `/auth/remind-password?login=${login}`)

export const passwordResetSetup = async (
	data: ResetPasswordData
): Promise<LoginResponse> => await requestCreator<ResetPasswordData, LoginResponse>('POST', `/auth/reset-password`, data, undefined, 'Ustawiono nowe hasło.')

export const passwordChange = async (
	data: ChangePasswordData
): Promise<LoginResponse> => await requestCreator<ChangePasswordData, LoginResponse>('POST', `/auth/change-password`, data, undefined, 'Ustawiono nowe hasło.')
