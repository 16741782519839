import {AllAttributes} from '@actions/configuratorActions';
import {HeightRangeModel, SpoilerOption} from '@api/models/configuration';
import {SPOILER_CATEGORY_ID} from '@constants/spoiler';
import {toast} from 'react-toastify';

export const getCategoriesFromSelectedAttributes = (
	allAttributesObject: AllAttributes | undefined,
	selectedAttributes: number[]
) => selectedAttributes.map((id) => allAttributesObject?.[id].categoryId);

export const isInScale = (value: number, currentHeight: number): boolean => {
	return value === currentHeight;
};

export const spoilerValidation = (
	heights: HeightRangeModel[] | undefined,
	height: number,
	categories: (number | undefined)[]
): boolean => {
	const currentScaleId = heights
		? Object.keys(heights).find((key) => isInScale(heights[+key].value, height))
		: undefined;

	if (currentScaleId !== undefined && heights) {
		const spoilerStatus = heights[+currentScaleId].spoilerOptionId;

		if (
			spoilerStatus === SpoilerOption.NONE &&
			categories.includes(SPOILER_CATEGORY_ID)
		) {
			toast.warning('Spojler nie może zostać dodany do tej wysokości pojazdu!');
			return false;
		}

		if (
			spoilerStatus === SpoilerOption.REQUIRED &&
			!categories.includes(SPOILER_CATEGORY_ID)
		) {
			toast.warning('Spojler musi zostać dodany do tej wysokości pojazdu!');
			return false;
		}
	}

	return true;
};
