import {CategoryModel} from '@api/models/configuration';
import Checkbox from '@components/_FormElements/Checkbox/Checkbox';
import React from 'react';
import {Title} from '@components/_Typography';
import {TitleTag, TitleVariant} from '@components/_Typography/Title/Title';
import styles from './Category.module.scss';

interface CategoryProps extends CategoryModel {
	form: any;
	updateFormHandler: any;
}

const Category = (props: CategoryProps) => {
	const {form, updateFormHandler, attributes, categoryName, isRequiredAttributeFromCategory: isRequired} = props;

	return (
		<div className={styles.wrapper}>
			<Title tag={TitleTag.h2} variant={TitleVariant.standard}>
				{categoryName} <small><em>{isRequired ? '(Wymagane)' : ''}</em></small>
			</Title>
			{attributes.map(
				(attribute) =>
					!!form.step2?.[attribute.attributeId] && (
						<Checkbox
							key={`${attribute.attributeId}`}
							id={`${attribute.attributeId}`}
							name={`${attribute.attributeId}`}
							classes={styles.item}
							label={attribute.name}
							required={false}
							value={form.step2?.[`${attribute.attributeId}`]?.value}
							valueChangeHandler={() => {
								updateFormHandler(`${attribute.attributeId}`, {
									isValid: true,
									errorMessage: '',
									value: !form.step2?.[`${attribute.attributeId}`]?.value,
									required: false,
								});
							}}
							toggler
							info={{
								title: attribute.name,
								description: attribute.description,
								imageUrl: attribute.imageUrl,
							}}
							disabled={attribute.isHidden}
						/>
					)
			)}
		</div>
	);
};

export default Category;
