import {requestCreator} from '@helpers/requsetCreator';
import {DataChangeData} from './models/userModels';
import { UserDataModel } from './models/authModels';

export const dataChange = async (data: DataChangeData): Promise<UserDataModel> =>
	await requestCreator<DataChangeData, UserDataModel>(
		'POST',
		`/user/edit`,
		data,
		undefined,
		'Dane zostały zmienione.'
	);
