import {
	DIGIT_REGEX,
	SPECIAL_CHAR_REGEX,
	UPPER_REGEX,
} from '@components/_FormElements/PasswordCheck/PasswordCheck';

/* eslint-disable no-control-regex */
const phoneNumberRegex =
	/^[+]?\d{1,4}[-. ]?[(]?\d{1,4}[)]?[-. ]?\d{1,9}[-. ]?\d{1,9}$/;
const emailRegex =
	/^(?:[A-Za-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[A-Za-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?\.)+[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/;

const validation = (
	inputTouched: boolean,
	inputValue: string | boolean | File | undefined | number | any[],
	inputName: string,
	required: boolean
): string => {
	if ((!required && !!!inputValue) || !inputTouched) return '';

	if (typeof inputValue === 'string') {
		switch (inputName) {
			case 'userPassword':
			case 'width':
			case 'height':
			case 'length':
				return '';
			case 'type':
				return  inputValue !== '0' ? '' : 'Wybierz typ zgłoszenia.';
			case 'name':
			case 'lastName':
			case 'company':
			case 'description':
				return inputValue.trim().length <= 2
					? 'Pole musi zawierać min. 2 znaki.'
					: '';
			case 'password':
			case 'passwordRepeat':
			case 'oldPassword':
				return !(
					UPPER_REGEX.test(inputValue) &&
					SPECIAL_CHAR_REGEX.test(inputValue) &&
					DIGIT_REGEX.test(inputValue) &&
					inputValue.trim().length >= 8
				)
					? 'Hasło jest za słabe'
					: '';

			case 'email':
				return !emailRegex.test(inputValue) ? 'E-mail jest nieprawidłowy.' : '';
			case 'phone':
				return !phoneNumberRegex.test(inputValue)
					? 'Numer jest nieprawidłowy.'
					: '';
			default:
				return 'Wartość jest niepoprawna';
		}
	}

	if (typeof inputValue === 'number') {
		switch (inputName) {
			case 'brand':
				return inputValue === 0 ? 'Wybierz markę' : '';
			default:
				return '';
		}
	}

	if (inputName === 'file')
		return required ? (!!inputValue ? '' : 'Zdjęcie jest wymagane.') : '';

	if (typeof inputValue === 'boolean' && required)
		return inputValue === true ? '' : 'Pole jest wymagane.';

	if (typeof inputValue === 'object' && required) {
		switch (inputName) {
			default:
				return 'Wartość jest niepoprawna';
		}
	}

	return required ? 'Nieudana walidacja.' : '';
};

export default validation;
